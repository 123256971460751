import React, { useState } from 'react'
import { MbscCalendarEvent } from '@mobiscroll/react/dist/src/core/shared/calendar-view/calendar-view.types'
import { useFormattedDate } from '../../../../hooks/FormattedDate'
import { FormattedAgeString } from '../../../../components/FormattedAge'
import { AgendaEntry, IconButton, IconImageTooltip, Typography } from 'saga-library/src'
import { getFormattedDuration, patientDisplayName } from 'saga-library/src/util'
import { Box, SvgIcon } from '@mui/material'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import { AppointmentMethod } from 'saga-client/src/types/schedule/Appointment'
import { AppointmentRoom, AppointmentState, AppointmentType } from '../../../../types/schedule'
import CallOutlinedIcon from '@mui/icons-material/CallOutlined'
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined'
import { ChartNotesIcon, FormIcon, LetterIcon, PrescriptionIcon } from '../../../patients/components/ChartIcons'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLongOutlined'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import { useNavigate, useParams } from 'react-router-dom'
import { useAccountContext } from '../../../../providers/AccountContext'
import { useAgendaContext } from '../../../../providers/AgendaContextProvider'
import { AppointmentStateDropdown } from '../../../../components/AppointmentStateDropdown'

export const AgendaAppointment = ({ event, dataTestId }: { event: MbscCalendarEvent, dataTestId: string }) => {
  const navigate = useNavigate()
  const { tenant_id } = useParams()
  const { buildTenantRoute } = useAccountContext()
  const { appointmentStates, rooms, onChangeState, onChangeRoom } = useAgendaContext()

  const { patient, state, type } = event

  const dob = useFormattedDate(patient.dob)
  let subtitle = dob || ""
  if (dob) {
    subtitle += ` (${FormattedAgeString(patient.dob, false)} old`
    if (patient.gender?.name) {
      subtitle += ` ${patient.gender.name.toLowerCase()})`
    } else {
      subtitle += ')'
    }
  }

  const openInNewTab = () => {
    window.open(buildTenantRoute(`schedule/?aId=${event.id}`, tenant_id))
  }

  const viewChart = () => {
    window.open(buildTenantRoute(`patients/p/${event.patient.id}`, tenant_id))
  }

  return (
    <AgendaEntry
      background={state?.color}
      typeColor={type?.color}
      start={event.start as string}
      end={event.end as string}
      location={event.locationName}
      openInNewTab={openInNewTab}
      title={
        <>
          <Typography variant={"h5"} lineclamp={1}>{patientDisplayName(patient.firstName, patient.lastName)}</Typography>
          {subtitle && <Typography variant={"p2"}>{subtitle}</Typography>}
        </>
      }
      description={
        <AgendaAppointmentDescription
          start={event.start as string}
          end={event.end as string}
          method={event.method}
          notes={event.notes}
          reasonForVisit={event.reasonForVisit}
          type={type}
        />
      }
      state={
        <AgendaAppointmentState
          states={appointmentStates}
          state={event.state}
          rooms={rooms}
          room={event.room}
          onChangeState={(state) => onChangeState(event.id as string, state, event.version)}
          onChangeRoom={(room) => onChangeRoom(event.id as string, room, event.version)}
          startTime={event.inProgressPit}
          dataTestId={dataTestId}
        />
      }
      viewChart={viewChart}
      actions={[
        <AgendaChartIcon
          key={"encounter-note"}
          label={"encounter note"}
          Icon={ChartNotesIcon}
          onClick={() => {
            navigate(buildTenantRoute(`patients/p/${event.patient.id}/encounter-notes/new?appointment_id=${event.id}`, tenant_id))
          }}
          dataTestId={`${dataTestId}-encounter-notes`}
        />,
        <AgendaChartIcon
          key={"prescription"}
          label={"prescription"}
          Icon={PrescriptionIcon}
          onClick={() => {
            navigate(buildTenantRoute(`patients/p/${event.patient.id}/prescriptions/new`, tenant_id))
          }}
          dataTestId={`${dataTestId}-prescription`}
        />,
        <AgendaChartIcon
          key={"form"}
          label={"form"}
          Icon={FormIcon}
          onClick={() => {
            navigate(buildTenantRoute(`patients/p/${event.patient.id}/forms/new`, tenant_id))
          }}
          dataTestId={`${dataTestId}-form`}
        />,
        <AgendaChartIcon
          key={"letter"}
          label={"letter"}
          Icon={LetterIcon}
          onClick={() => {
            navigate(buildTenantRoute(`patients/p/${event.patient.id}/letters/new`, tenant_id))
          }}
          dataTestId={`${dataTestId}-letter`}
        />,
        <AgendaChartIcon
          key={"claim"}
          label={"claim"}
          Icon={ReceiptLongIcon}
          onClick={() => {
            navigate(buildTenantRoute(`billing?pId=${event.patient.id}&serviceDate=${event.itemDate}`, tenant_id))
          }}
          dataTestId={`${dataTestId}-claim`}
        />,
        <AgendaChartIcon
          key={"task"}
          label={"task"}
          Icon={TaskAltIcon}
          onClick={() => {
            navigate(buildTenantRoute(`patients/p/${event.patient.id}/tasks/new`, tenant_id))
          }}
          dataTestId={`${dataTestId}-task`}
        />
      ]}
    />
  )
}


const AgendaAppointmentDescription = ({
  start,
  end,
  method,
  notes,
  reasonForVisit,
  type
}: {
  start: string,
  end: string,
  method: AppointmentMethod,
  notes?: string,
  reasonForVisit?: string,
  type: AppointmentType
}) => {
  const [open, setOpen] = useState<boolean>(false)

  if (type) {
    return (
      <>
        <Box display={"flex"} alignItems={"center"} gap={"4px"}>
          <Typography variant={"p2"}>{type?.name} ({getFormattedDuration(start, end)})</Typography>
          {method === AppointmentMethod.PHONE && <CallOutlinedIcon sx={{ fontSize: '16px', color: (theme) => theme.palette.greys.dark}}/>}
          {method === AppointmentMethod.VIDEO && <VideocamOutlinedIcon sx={{ fontSize: '18px', color: (theme) => theme.palette.greys.dark }}/>}
        </Box>
        {(reasonForVisit || notes) && (
          <Box display={"flex"} alignItems={"center"} gap={"4px"}>
            {reasonForVisit && (
              <Typography variant={"p2"} lineclamp={1}>{reasonForVisit}</Typography>
            )}
            {notes && (
              <IconImageTooltip
                open={open}
                placement={"bottom-start"}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                title={<Typography variant={"body1"}>{notes}</Typography>}
                popperSx={{
                  backgroundColor: (theme) => theme.palette.background.paper,
                  color: (theme) => theme.palette.greys.dark,
                  boxShadow: (theme) => (theme.shadows as string[])[5],
                  borderRadius: "8px",
                  maxHeight: "392px",
                  maxWidth: "384px",
                  overflow: "hidden",
                  whiteSpace: "break-spaces"
                }}
              >
                <DescriptionOutlinedIcon fontSize={"small"} sx={{ alignSelf: "center", color: (theme) => theme.palette.greys.medium }} />
              </IconImageTooltip>
            )}
          </Box>
        )}
      </>
    )
  }

  return <Typography variant={"p2"}>{getFormattedDuration(start, end)}</Typography>
}


const AgendaAppointmentState = ({
  states = [],
  state,
  rooms = [],
  room,
  onChangeState,
  onChangeRoom,
  startTime,
  dataTestId
}: {
  states: AppointmentState[],
  state: AppointmentState,
  rooms: AppointmentRoom[],
  room?: AppointmentRoom,
  onChangeState: (state: AppointmentState) => void,
  onChangeRoom: (room: AppointmentRoom) => void,
  startTime?: string,
  dataTestId: string
}) => {
  return (
    <AppointmentStateDropdown
      name={"state"}
      value={state}
      room={room}
      startTime={startTime}
      options={states}
      rooms={rooms}
      onChangeState={onChangeState}
      onChangeRoom={onChangeRoom}
      dataTestId={`${dataTestId}-state-dropdown`}
    />
  )
}

const AgendaChartIcon = ({
  label,
  Icon,
  hasLinkedItem = false,
  onClick,
  dataTestId
}: {
  label: string,
  Icon: typeof SvgIcon,
  hasLinkedItem?: boolean,
  onClick: () => void,
  dataTestId: string
}) => {
  return (
    <IconImageTooltip title={hasLinkedItem ? `View existing ${label}(s)` : `Create ${label}`}>
      <IconButton
        icon={<Icon fontSize={"small"} />}
        onClick={onClick}
        sx={hasLinkedItem
          ? {
              backgroundColor: 'greys.medium',
              color: 'white',
              "&:hover": {
                backgroundColor: 'greys.medium'
              }
            }
          : {}
        }
        dataTestId={`${dataTestId}-button`}
      />
    </IconImageTooltip>
  )
}