import React, { useCallback, useEffect, useRef, useState } from 'react'
import { TextField, Typography, AddButton } from 'saga-library/src'
import { Box, SxProps } from '@mui/material'
import { useTheme } from '@mui/material'
import { useFormContext, useWatch } from 'saga-library/src/components/Form'
import { PatientSearch } from '../SearchControls/Patient/PatientSearch'
import FormattedDatePicker from '../FormattedDatePicker'
import { rules } from '../../apps/patients/util/validation'
import { GenderSelect } from '../GenderSelect'
import { Theme } from '@mui/system'
import { NewPatientDefaults } from '../SearchControls/Patient/PatientModal'


interface PatientLookupProps {
  label: string,
  name: string,
  includeInactive?: boolean,
  defaultTValue?: any
  patientLabelSx?: SxProps<Theme>,
  sx?: SxProps<Theme>,
  getNewDefaults?: () => NewPatientDefaults
  patientLabelVariant?: 'sm' | 'md'
}

export const PatientLookup = ({
  label,
  name,
  includeInactive = false,
  defaultTValue,
  patientLabelSx,
  sx,
  getNewDefaults,
  patientLabelVariant = 'sm',
}: PatientLookupProps) => {

  const { setValue, control } = useFormContext()

  const [showPatientOption, setShowPatientOption] = useState<boolean>(false)
  const [showDetails, setShowDetails] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [hasOptions, setHasOptions] = useState<boolean>(false)
  const [inputText, setInputText] = useState<boolean>(false)

  const theme = useTheme()
  const patientInputRef = useRef<any>()

  const patient = useWatch({
    control,
    name: 'patient'
  })

  const onInputValueChanged = (event, value, reason) => {
    setInputText(value)
  }

  useEffect(() => {
    if (inputText) {
      if (checkPHN(inputText) && !hasOptions) {
        setShowPatientOption(true)
        setOpen(false)
      } else {
        setShowPatientOption(false)
        setShowDetails(false)
        setValue('patientPHN', null)
        if (!patient) {
          setOpen(true)
        }
      }
    }
  }, [inputText, hasOptions])

  useEffect(() => {
    if (patient) {
      setOpen(false)
    }
  }, [patient])

  //Dummy PHN validity
  const checkPHN = useCallback((value) => {
    return !patient && value.length === 9 && /^[0-9.,]+$/.test(value)
  }, [patient])

  //Load the info form, set PHN value
  const addNewPatient = useCallback(() => {
    const phn = patientInputRef.current?.value
    setValue('patientPHN', phn)
    setShowDetails(true)
  }, [patientInputRef, setShowDetails, setValue])

  //Keyboard entry -- open the new patient info on unaltered enter
  const handleKeyPress = useCallback((event) => {
    if (document.activeElement === patientInputRef.current && event.key === 'Enter' && !event.altKey && !event.shiftKey && !event.ctrlKey) {
      if (showPatientOption) {
        addNewPatient()
      }
    }
  }, [addNewPatient, patientInputRef, showPatientOption])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleKeyPress])

  return (
    <>
      <PatientSearch
        name={name}
        label={label}
        includeInactive={includeInactive}
        sx={sx}
        patientLabelSx={patientLabelSx}
        defaultTValue={defaultTValue}
        patientLabelVariant={patientLabelVariant}
        onInputChange={onInputValueChanged}
        clearOnBlur={false}
        inputRef={patientInputRef}
        open={open}
        onOpen={(e) => {
          if (!patient && !showPatientOption) {
            setOpen(true)
          }
        }}
        onClose={(e) => {
          setOpen(false)
        }}
        onOptionsChanged={(options) => {
          setHasOptions(options.length > 0)
        }}
        autoSelect={false}
        tabAutoSelect={true}
        getNewDefaults={getNewDefaults}
      />
      {showPatientOption && (
        <>
          <Typography
            variant={'body2'}
            sx={{
              ml: 2,
              mt: '-4px',
              mb: '4px',
              lineHeight: '90%',
              color: theme.palette.tertiary.orange
            }}
          >
            PHN not found. A new patient will be created.
          </Typography>
          {showDetails ? (
            <InlinePatientForm />
          ) : (
            <AddButton
              label={'Add details for new patient'}
              onClick={addNewPatient}
              sx={{ m: '-8px 0 8px 0' }}
              dataTestId={'newPatient-details'}
            />
          )}
        </>
      )}
    </>
  )
}

const InlinePatientForm = () => {
  return (
    <Box
      sx={(theme) => ({
        border: `solid 1px ${theme.palette.greys.light}`,
        borderRadius: 1,
        p: '0 8px',
        mb: 1
      })}
    >
      <TextField
        label={'Last name'}
        name={'patientLastName'}
        sx={{ width: '100%' }}
        inputProps={{ maxLength: rules.lastName.max }}
        autoFocus={true}
      />
      <TextField
        label={'First name'}
        name={'patientFirstName'}
        sx={{ width: '100%' }}
        inputProps={{ maxLength: rules.firstName.max }}
      />
      <Box
        sx={{
          display: 'flex'
        }}
      >
        <FormattedDatePicker
          label={'Date of birth'}
          name={'patientDOB'}
          sx={{
            mr: 1,
            flex: '1 1 auto'
          }}
        />
        <GenderSelect name={'patientGenderId'} />
      </Box>
    </Box>
  )
}