import { FilterSelect } from 'saga-library/src'
import React, { useCallback, useEffect } from 'react'
import { useSuspenseQuery } from '@apollo/client'
import { GET_TENANT_USERS } from '../../../graphql-definitions'
import _get from 'lodash/get'
import { useParams } from 'react-router-dom'
import { useAccountContext } from '../../../providers/AccountContext'
import { FilterSelectOption } from 'saga-library/src/components/FilterSelect/FilterSelect'
import { Role, User } from '../../../types/account'

export const UserRoleSelect = ({onChange, sx={}, inputSx={}}) => {
  const { tenant_id, user_id, role_id } = useParams()
  const { userId} = useAccountContext()

  const { data } = useSuspenseQuery(GET_TENANT_USERS, {
    variables: { tenantId: tenant_id },
    fetchPolicy: 'cache-and-network'
  })

  const userList: User[] = _get(data, 'tenant.user.allTenantUsers', [])
  const roleList: Role[] = _get(data, 'tenant.role.tenantRoles', [])

  const getDefault = () => {
    if(userList && roleList) {
      if (user_id) {
       return userList.find(u => u.id === user_id)
      }

      if(role_id){
        return roleList.find(r => r.id === role_id)
      }

      return userList.find(u => u.id === userId)
    }
  }

  useEffect(()=>{
    const defaultSelection = getDefault()
    if(defaultSelection){
      onChange({
        value: defaultSelection
      })
    }
  }, [ userList, roleList ])


  const getUserOptions = (userList) => userList.map(user => {
    return {label: `${user.lastName}, ${user.firstName}`, value: user}
  })

  const getRoleOptions = (roleList) => roleList.filter(r => !r.isAdmin && !r.isOwner).map(role => {
    return {label: `${role.name}`, value: role}
  })

  const getOptions = useCallback(()=>{
    const userOptions = getUserOptions(userList)
    const roleOptions = getRoleOptions(roleList)
    var options:FilterSelectOption[]  = []
    if((!userOptions || userOptions.length === 0) && (!roleOptions || roleOptions.length === 0)) {
      return options
    }

    if(roleOptions && roleOptions.length >= 1) {
      options = options.concat(roleOptions)
    }

    if(userOptions && userOptions.length >= 1) {
      if (options.length >= 1) {
        options.push({type:"divider"})
      }

      options = options.concat(userOptions)
    }

    return options
  },[roleList, userList])

  return (
    <FilterSelect
      dataTestId={"tasks-userSelect"}
      className={"test"}
      options={getOptions()}
      onSelect={onChange}
      defaultSelectedValue={getDefault()}
      size={'large'}
      sx={{...sx}}
      inputSx={{ justifyContent: 'left', ...inputSx }}
    />
  )
}