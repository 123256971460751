import { ErrorBoundary } from 'saga-library/src'
import { Box } from '@mui/material'
import React, { Suspense } from 'react'
import { LoadingSpinner } from './LoadingScreen'

export const ComponentSuspenseWrapper = ({ children }) => {
  return (
    <ErrorBoundary
      fallback={<Box color={"red"}>Failed to load</Box> }
    >
      <Suspense fallback={<Box display="flex" flexDirection="column" className={"loading"} height={"100%"}>
        <LoadingSpinner sx={{height: "100%"}}/>
      </Box>}>
        { children }
      </Suspense>
    </ErrorBoundary>
  )
}