import { gql } from "@apollo/client";
import { PRESCRIPTION_RESULT_FRAGMENT } from "./users/TenantUserFavouriteQueries";

export const CLINIC_PRACTITIONER_SEARCH = gql`
query SearchGlobal($tenantId: ID!, $searchParam: String) {
        tenant(id: $tenantId) {
            search(param: $searchParam) {
                practitioner {
                    searchResults {
                        id
                        title
                        firstName
                        lastName
                        primaryIdentifier
                    }
                }
            }
        }
    }
`

export const REFERRAL_PRACTITIONER_SEARCH = gql`
  query ReferralPractitionerSearch($tenantId: ID!, $searchParam: String, $activeOnly: Boolean) {
    tenant(id: $tenantId) {
      search(param: $searchParam) {
        referralPractitioner {
          searchResults(activeOnly: $activeOnly) {
            id
            firstName
            lastName
            practitionerId
            label
            street1
            street2
            street3
            city
            province
            disciplineDescription
            skills {
              id
              description
              skillCode
            }
            locationId
            active
          }
        }
      }
    }
  }
`

export const GET_SEARCH_DIAGNOSTICCODES = gql`
    query GetDiagnosticCodes(
        $tenantId: ID!
        $searchParam: String
        $serviceDate: Date!
    ) {
        tenant(id: $tenantId) {
            search(param: $searchParam) {
                param
                abClaim(serviceDate: $serviceDate) {
                    diagnosticCodes {
                        id
                        description
                        diagnosticCode
                    }
                }
            }
        }
    }
`

export const GET_RECENT_DIAGNOSTICCODES = gql`
    query GetRecentDiagnosticCodes(
        $tenantId: ID!
        $searchPractitionerId: ID!
        $serviceDate: Date!
    ) {
        tenant(id: $tenantId) {
            search {
                abClaim(serviceDate: $serviceDate) {
                    recentDiagnosticCodes(practitionerId: $searchPractitionerId) {
                        id
                        description
                        diagnosticCode
                    }
                }
            }
        }
    }
`


export const GET_SEARCH_SERVICECODES = gql`
    query GetServiceCode(
        $tenantId: ID!
        $searchParam: String
        $serviceDate: Date!
    ) {
        tenant(id: $tenantId) {
            search(param: $searchParam) {
                param
                abClaim(serviceDate: $serviceDate) {
                    serviceCodes {
                        id
                        serviceCode
                        description
                        baseAmount
                    }
                }
            }
        }
    }
`


export const GET_RECENT_SERVICECODES = gql`
    query GetRecentServiceCodes(
        $tenantId: ID!
        $searchPractitionerId: ID!
        $serviceDate: Date!
    ) {
        tenant(id: $tenantId) {
            search {
                abClaim(serviceDate: $serviceDate) {
                    recentServiceCodes(practitionerId: $searchPractitionerId) {
                        id
                        serviceCode
                        description
                        baseAmount
                    }
                }
            }
        }
    }
`


export const GET_SEARCH_FACILITY = gql`
    query GetFacilityResults(
        $tenantId: ID!
        $searchParam: String
        $serviceDate: Date!
    ) {
        tenant(id: $tenantId) {
            search(param: $searchParam) {
                param
                abClaim(serviceDate: $serviceDate) {
                    facilities {
                        id
                        description
                        facilityType
                    }
                }
            }
        }
    }
`


export const GET_RECENT_FACILITY = gql`
    query GetRecentFacilities(
        $tenantId: ID!
        $searchPractitionerId: ID!
        $serviceDate: Date!
    ) {
        tenant(id: $tenantId) {
            search {
                abClaim(serviceDate: $serviceDate) {
                    recentFacilities(practitionerId: $searchPractitionerId) {
                        id
                        description
                        facilityType
                    }
                }
            }
        }
    }
`


export const GET_CITY = gql`
    query GetCities($tenantId: ID!, $searchParam: String, $provinceCode: String) {
        tenant(id: $tenantId) {
            search(param: $searchParam) {
                reference {
                    citiesByProvince(province: $provinceCode) {
                        id
                        name
                    }
                }
            }
        }
    }
`

const PATIENT_RESULT_FRAGMENT = gql`
    fragment PatientResultFragment on PatientResult {
        id
        firstName
        lastName
        dob
        email
        primaryPhoneNumber
        primaryIdentifierLabel
        primaryIdentifier
        inactiveDate
        serviceCode {
            id
            serviceCode
            description
        }
        diagnosticCodes {
            id
            diagnosticCode
            description
        }
        patientNotes {
            noteText
        }
        identifiers {
          typeId
          value
          label
        }
        province
    }
    `

export const GET_SEARCH_PATIENTS = gql`
    ${PATIENT_RESULT_FRAGMENT}
    query GetSearchPatients($tenantId: ID!, $searchParam: String, $includeInactive: Boolean) {
        tenant(id: $tenantId) {
            search(param: $searchParam) {
                param
                patients {
                    searchResults(includeInactive:$includeInactive) {
                        ...PatientResultFragment
                    }
                }
            }
        }
    }
`

export const GET_SEARCH_PATIENT_IDENTIFIERS = gql`
    query GetSearchPatientIdentifiers($tenantId: ID!, $searchParam: String) {
        tenant(id: $tenantId) {
            search(param: $searchParam) {
                patients {
                    identifierSearchResults {
                        id
                        typeId
                        value
                        label
                    }
                }
            }
        }
    }
`

export const GET_RECENT_PATIENTS = gql`
    ${PATIENT_RESULT_FRAGMENT}
    query GetRecentPatient($tenantId: ID!, $includeInactive: Boolean) {
        tenant(id: $tenantId) {
            search {
                patients {
                    recent(includeInactive:$includeInactive) {
                        ...PatientResultFragment
                    }
                }
            }
        }
    }
    `


export const GET_SEARCH_GLOBAL = gql`
    query SearchGlobal($tenantId: ID!, $searchParam: String, $includeInactive: Boolean) {
        tenant(id: $tenantId) {
            search(param: $searchParam) {
                patients {
                    searchResults(includeInactive:$includeInactive) {
                        id
                        firstName
                        lastName
                        dob
                        primaryIdentifierLabel
                        primaryIdentifier
                        identifiers {
                          typeId
                          value
                          label
                        }
                        inactiveDate
                        province
                    }
                }
                practitioner {
                    searchResults {
                        id
                        title
                        firstName
                        lastName
                        primaryIdentifier
                    }
                }
            }
        }
    }
`

export const GET_RECENT_GLOBAL = gql`
    query GetRecentGlobal($tenantId: ID!, $includeInactive: Boolean) {
        tenant(id: $tenantId) {
            search{
                patients {
                    recent(includeInactive:$includeInactive) {
                        id
                        firstName
                        lastName
                        dob
                        primaryIdentifierLabel
                        primaryIdentifier
                        identifiers {
                          typeId
                          value
                          label
                        }
                        inactiveDate
                        province
                    }
                }

            }
        }
    }
`

export const GET_SEARCH_ALLERGENS = gql`
    query GetSearchAllergen($tenantId: ID!, $searchParam: String) {
        tenant(id: $tenantId) {
            search(param: $searchParam) {
                allergens {
                    searchResults {
                        code
                        display
                    }
                }
            }
        }
    }
`

export const GET_SEARCH_PHARMACIES = gql`
    query GetSearchPharmacy($tenantId: ID!, $searchParam: String) {
        tenant(id: $tenantId) {
            search(param: $searchParam) {
                pharmacies {
                    searchResults {
                        id
                        name
                        street1
                        street2
                        street3
                        city
                        province
                        postalCode
                        phoneNumber
                        faxNumber
                    }
                }
            }
        }
    }
`

export const GET_SEARCH_VACCINATIONS = gql`
    query GetSearchVaccination($tenantId: ID!, $searchParam: String) {
        tenant(id: $tenantId) {
            search(param: $searchParam) {
                vaccinations {
                    searchResults {
                        id
                        name
                    }
                }
            }
        }
    }
`

export const SEARCH_FOR_APPT_SLOTS= gql`
    query SearchAppointments($tenantId: ID!, $first: Int!, $after: String, $slotCriteria: AppointmentSlotSearchInput!) {
        tenant(id: $tenantId) {
            appointmentSearch {
                searchResults(first: $first, after: $after, slotCriteria: $slotCriteria) {
                    edges{
                        cursor
                        node {
                            resource: scheduleId
                            start
                            end
                        }
                    }
                    pageInfo {
                        startCursor
                        endCursor
                        hasNextPage
                        hasPreviousPage
                    }
                }
            }
        }
    }
`

export const GET_SEARCH_PRESCRIPTIONS_AND_DRUGS = gql`
    ${PRESCRIPTION_RESULT_FRAGMENT}
    query GetSearchPrescriptionsAndDrugs($tenantId: ID!, $searchParam: String) {
        tenant(id: $tenantId) {
            search(param: $searchParam) {
                reference {
                    prescriptionFavouriteAndRecentSearchResults {
                        prescriptionUserTypes
                        ...PrescriptionResultFragment
                    }
                    drugSearchResults {
                        name
                        code
                        codeType
                        brand
                        drug
                        ingredient
                    }
                }
            }
        }
    }
`

export const GET_SEARCH_LINKED_DOCUMENT_DESCRIPTIONS = gql`
    query GetSearchLinkedDocumentDescriptions($tenantId: ID!, $searchParam: String, $category: DocumentCategory) {
        tenant(id: $tenantId) {
            search(param: $searchParam) {
                linkedDocuments {
                    recentDescriptions(category: $category)
                }
            }
        }
    }
`