import React from 'react'
import { AgendaEntry, Typography } from 'saga-library/src'
import { getFormattedDuration } from 'saga-library/src/util'

export const AgendaEvent = ({ event }) => {
  return (
    <AgendaEntry
      background={"repeating-linear-gradient(-45deg, #f3f3f3, #f3f3f3 11px, #e5e5e5 11px, #e5e5e5 22px)"}
      allDay={event.allDay}
      start={event.start}
      end={event.end}
      title={event.title || ""}
      location={event.locationName}
      description={!event.allDay && (
        <Typography variant={"p2"}>
          {getFormattedDuration(event.start, event.end)}
        </Typography>
      )}
    />
  )
}