import React, { Suspense, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { FilterType, LabsList } from './components/Labs/LabsList'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { LoadingSpinner } from '../../components/LoadingScreen'
import { LabsHeader } from './components/Labs/LabsHeader'
import { EmptyList, Section } from 'saga-library/src'
import AssignedTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined'
import { ReviewLab } from '../../types/inbox/ReviewLab'
import { useAccountContext } from '../../providers/AccountContext'
import { useReviewLabsContext } from './providers/ReviewLabsProvider'
import { FormSection } from './components/Tasks/FormSection'
import { LabsMultipleSelected } from './components/Labs/LabsMultipleSelected'

interface LabsProps {
  dataTestId?: string
}

export const Labs = ({
  dataTestId = 'inbox'
}: LabsProps) => {
  const navigate = useNavigate()
  const { buildTenantRoute } = useAccountContext()

  const { user_id, role_id, tenant_id } = useParams()
  const { loadPractitionerLabs, practitionerLabs } = useReviewLabsContext()

  const [selectedLabs, setSelectedLabs] = useState<string[]>([])
  const [clearSelectedLabs, setClearSelectedLabs] = useState<boolean>(false)
  const [nextLabId, setNextLabId] = useState<string | undefined>(undefined)

  const [listFilters, setListFilters] = useState({
    labsAndInvestigations: '*',
    unreviewed: 'unreviewed',
    normalAndAbnormal: '*',
  })

  useEffect(() => {
    setClearSelectedLabs(true)
  }, [listFilters])

  useEffect(() => {
    if(!tenant_id || !user_id || !loadPractitionerLabs) return

    loadPractitionerLabs({
        tenantId: tenant_id,
        userId: user_id,
    }, { fetchPolicy: 'cache-and-network' })
  }, [user_id])

  const onClick = (lab : ReviewLab | null) => {
    if(!lab) {
      navigate(buildTenantRoute(`inbox/${role_id ? `r/${role_id}` : `u/${user_id}`}/labs/`, tenant_id))
      return
    }
    navigate(buildTenantRoute(`inbox/${role_id ? `r/${role_id}` : `u/${user_id}`}/labs/l/${lab.id}`, tenant_id))
  }

  const navigateToNextLab = () => {
    if (!!nextLabId) {
      navigate(buildTenantRoute(`inbox/u/${user_id}/labs/l/${nextLabId}`, tenant_id))
    } else {
      navigate(buildTenantRoute(`inbox/u/${user_id}/labs`, tenant_id))
    }
  }

  const onSuccess = () => {
    setClearSelectedLabs(true)
    navigateToNextLab()
  }

  const setFilters = (event: any, type: FilterType) => {
    switch(type){
      case 'labsAndInvestigations':
        setListFilters({...listFilters, labsAndInvestigations: event.value})
        break
      case 'unreviewed':
        setListFilters({...listFilters, unreviewed: event.value})
        break
      case 'normalAndAbnormal':
        setListFilters({...listFilters, normalAndAbnormal: event.value})
        break
    }
  }

  const view = () => {
    if (selectedLabs.length > 0 && listFilters.unreviewed === 'unreviewed') {
      return (
        <LabsMultipleSelected
          onSuccess={() => onSuccess()}
          selectedLabs={selectedLabs}
          clearSelected={() => setClearSelectedLabs(true)}
        />
      )
    }

    return (
      <Outlet />
    )
  }

  return (
    <>
      <Section.Column
        width={'40%'}
        sx={{
          pr: 1,
        }}
        header={
          <LabsHeader
            setFilters={setFilters}
            dataTestId={dataTestId}
          />
        }
      >
        <Suspense fallback={<LoadingSpinner />}>
          {
            practitionerLabs && (
              <LabsList
                setSelectedLabs={setSelectedLabs}
                clearSelectedLabs={clearSelectedLabs}
                setClearSelectedLabs={setClearSelectedLabs}
                setNextLabId={setNextLabId}
                onClick={onClick}
                listFilters={listFilters}
                dataTestId={dataTestId}
              />
            )
          }
        </Suspense>
      </Section.Column>
      <FormSection>
        { view() }
      </FormSection>
    </>
  )
}


export const EmptyLabSelection = () => {
  return (
    <Section.Column rightPadding={1} sx={{ flex: "1 1 100%" }}>
      <EmptyList icon={AssignedTurnedInOutlinedIcon} message={'Select a lab to view it.'} />
    </Section.Column>
  )
}