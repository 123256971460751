import React from "react";
import { useParams } from 'react-router-dom'
import { Permission, PermissionType } from "../types/settings/Permission";
import { useAccountContext } from "../providers/AccountContext";
import { usePageTitleContext } from "../providers/PageTitleContextProvider";
import { Box, Typography } from '@mui/material'
import moment from "moment-timezone";
import {
  LabsAndInvestigationsDashboardException
} from '../apps/dashboard/components/LabsAndInvestigations/LabsAndInvestigationsDashboardException'
import { AppointmentsDashboard } from '../apps/dashboard/components/Appointments/AppointmentsDashboard'
import { useQuery } from '@apollo/client'
import { GET_TENANT_USER_BY_ID } from '../graphql-definitions'
import _get from 'lodash/get'

export const Dashboard = React.memo(() => {
  const { tenant_id } = useParams()

  const { getTenantName, userId } = useAccountContext()
  const { setAreaTitle } = usePageTitleContext()
  const tenantName = getTenantName(tenant_id)

  const { userHasPermission } = useAccountContext()
  const hasChartPermissions = (tenant_id && userHasPermission(tenant_id, PermissionType.Chart, Permission.READWRITE))

  const { data: tenantUserData } = useQuery(GET_TENANT_USER_BY_ID, {
    variables: {
      tenantId: tenant_id,
      userId: userId
    },
    fetchPolicy: 'cache-and-network'
  })

  React.useEffect(() => {
    setAreaTitle('Dashboard')
  }, [setAreaTitle])

  const isPractitioner = _get(tenantUserData, 'tenant.user.user', null)?.isPractitioner

  if (isPractitioner) {
    const practitionerId = _get(tenantUserData, 'tenant.user.user', null).practitionerIds?.[0]
    return (
      <Box display={'flex'} flexDirection={'row'} height={'100%'}>
        <AppointmentsDashboard practitionerId={practitionerId} />
        {hasChartPermissions && (
          <LabsAndInvestigationsDashboardException sx={{ minWidth: '621px', maxHeight: 'calc(100% - 3px)' }} />
        )}
      </Box>
    )
  }

  if (hasChartPermissions) {
    return (
      <div>
        <LabsAndInvestigationsDashboardException sx={{ minWidth: '780px' }} />
      </div>
    )
  }

  return (
    <div>
      <Typography variant={"h2"}>{generateGreeting()}</Typography>
      <div style={{ marginTop: 16, textAlign: "left" }}>{tenantName}</div>
    </div>
  )
})

const generateGreeting = () => {
  const hour = moment().hour()

  if (hour > 16) {
    return 'Good evening'
  }

  if (hour > 11) {
    return 'Good afternoon'
  }

  return 'Good morning'
}
