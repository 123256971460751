import { gql } from "@apollo/client";

export const APPOINTMENT_TYPE_FRAGMENT = gql`
  fragment AppointmentTypeFragment on AppointmentType {
    id
    version
    name
    duration
    color
    inPerson
    byPhone
    videoCall
    allSchedules
    schedules {
      id
    }
  }
`

export const ADD_NEW_APPOINTMENT_TYPE = gql`
  ${APPOINTMENT_TYPE_FRAGMENT}
  mutation CreateAppointmentType($tenantId: ID!, $appointmentTypeInput: AppointmentTypeInput!) {
    tenant(id: $tenantId) {
      schedule {
        type {
          create(input: $appointmentTypeInput) {
            ...AppointmentTypeFragment
          }
        }
      }
    }
  }
`


export const LIST_APPOINTMENT_TYPES = gql`
  query ListAppointmentTypes($tenantId: ID!) {
    tenant(id: $tenantId) {
      schedule {
        type {
          listTypes {
            id
            version
            name
            duration
            color
            inPerson
            byPhone
            videoCall
            allSchedules
            schedules {
              id
              location {
                name
              }
              practitionerFirstName
              practitionerLastName
              practitionerId
            }  
          }
        }
      }
    }
  }
`


export const DELETE_APPOINTMENT_TYPE = gql`
  mutation DeleteAppointmentType($tenantId: ID!, $appointmentTypeId: ID!, $version: String!) {
    tenant(id: $tenantId) {
      schedule {
        type {
          delete(id: $appointmentTypeId, version: $version)
        }
      }
    }
  }
  `

export const UPDATE_APPOINTMENT_TYPE = gql`
  ${APPOINTMENT_TYPE_FRAGMENT}
  mutation UpdateAppointmentType($tenantId: ID!, $appointmentTypeId: ID!, $appointmentTypeInput: AppointmentTypeInput!) {
    tenant(id: $tenantId) {
      schedule {
        type {
          update(id: $appointmentTypeId, input: $appointmentTypeInput) {
            ...AppointmentTypeFragment
          }
        }
      }
    }
  }
`