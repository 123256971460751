import { useAccountContext } from '../../../providers/AccountContext'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import React, { ReactNode, Suspense, useCallback, useEffect } from 'react'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import DocumentsIcon from '@mui/icons-material/DescriptionOutlined'
import { ErrorBoundary, Section, SubsectionMenu, SubsectionMenuItem, Typography, Spinner, IconButton } from 'saga-library/src'
import { Box } from '@mui/material'
import { LoadingSpinner } from '../../../components/LoadingScreen'
import UploadFileIcon from '@mui/icons-material/UploadFileOutlined'
import { ReactComponent as LabsImagingIcon } from '../../../assets/LabsImagingIcon.svg'
import { UserRoleSelect } from './UserRoleSelect'
import { UserRole, UserRoleType } from '../Inbox'
import { NetworkStatus, useLazyQuery, useQuery } from '@apollo/client'
import { GET_REVIEW_COUNTS } from '../../../graphql-definitions'
import _get from 'lodash/get'
import { User } from '../../../types/account'
import RefreshIcon from '@mui/icons-material/Refresh'
import { REFETCH_CURRENT_INBOX } from '../../../graphql-definitions/tenant/inbox/ReviewQueries'

export enum InboxSections {
  TASKS = "Tasks",
  DOCUMENTS = "Documents",
  LABS = "Labs and investigations"
}

export const InboxMenu = ({onChange}) => {
  const { tenant_id, role_id, user_id } = useParams()
  const location = useLocation()
  const currentPath = location.pathname

  const [currentUserRole, setCurrentUserRole] = React.useState<UserRole|null>(null)

  const {data, loading, refetch, networkStatus} = useQuery(GET_REVIEW_COUNTS, {
    variables:{
      tenantId: tenant_id,
      userId: user_id,
      roleId: role_id,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: !user_id && !role_id
  })

  const [refetchInboxData, {loading: refetchLoading}] = useLazyQuery(REFETCH_CURRENT_INBOX,{
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      console.log(data)
    }
  })

  useEffect(() => {
    if(tenant_id && (role_id || user_id)){
      refetch({
        tenantId: tenant_id,
        userId: user_id,
        roleId: role_id
      })
    }
  }, [role_id, user_id])

  const getRoutes = useCallback(():{route:string, text:string, icon:ReactNode}[]=> {
    if(currentUserRole) {
      const isUser = currentUserRole.type === UserRoleType.USER
      let route = isUser ? `u/${currentUserRole.data.id}/` : `r/${currentUserRole.data.id}/`

      const routes = [
        {
          route: route + 'tasks',
          text: InboxSections.TASKS,
          icon: <TaskAltIcon />
        }
      ]

      if (isUser) {
        if ((currentUserRole?.data as User).isPractitioner) {
          routes.push(
            {
              route: route + `labs`,
              text: InboxSections.LABS,
              icon: <LabsImagingIcon />
            }
          )
        }
        routes.push(
          {
            route: route + 'documents',
            text: InboxSections.DOCUMENTS,
            icon: <DocumentsIcon />
          }
        )
      }

      return routes
    }

    return []
  },[currentUserRole])

  const InboxLabel = ({label, count = 0, countLoading = false}) => {
    return (
      <Box display={"flex"} width={200} gap={0.5}>
        <Typography variant={'h5'} lineclamp={1} sx={{flex: "0 0 auto"}}>{label}</Typography>
        {countLoading ?
          <Box width={14} flex={"0 0 14px"}>
            <Spinner size={'xs'} />
          </Box> :
          count > 0 && <Typography variant={'h5'} lineclamp={1} >{`(${count})`}</Typography>}
      </Box>
    )
  }

  const InboxRoute = ({ route, text, icon, count = 0, countLoading = false}) => {
    return (
      <NavLink to={route}>
        {({ isActive }) => (
          <SubsectionMenuItem
            dataTestId={`inboxMenu-menuItem-${text}`}
            isActive={isActive}
            startIcon={icon}
            primaryText={<InboxLabel label={text} count={count} countLoading={countLoading || refetchLoading}/>}
          />
        )}
      </NavLink>
    )
  }

  const onUserRoleChange = (option) => {
    if(currentUserRole){
      onChange({
        type: option.value && 'emailAddress' in option.value ? UserRoleType.USER : UserRoleType.ROLE,
        data: option.value
      })
    }
    setCurrentUserRole({
      type: option.value && 'emailAddress' in option.value ? UserRoleType.USER : UserRoleType.ROLE,
      data: option.value
    })
  }

  const getCounts = (section) => {
    if(loading){
      return {
        tasks: undefined,
        documents: undefined,
        labs: undefined
      }
    }
    const inboxCounts = _get(data, 'tenant.review.inboxCounts', [])
    const sectionLC = section.toLowerCase()
    if(sectionLC.includes("labs")){
      return inboxCounts.labs || 0
    }

    return inboxCounts[sectionLC.toLowerCase()] || 0
  }

  const onRefetch = () => {
    let refetchSection
    if(currentPath.includes("tasks")){
      refetchSection = "tasks"
    }else if(currentPath.includes("labs")){
      refetchSection = "labs"
    } else {
      refetchSection = "docs"
    }
    refetchInboxData({
      variables: {
        tenantId: tenant_id,
        userId: user_id,
        roleId: role_id,
        getTasks: refetchSection === "tasks",
        getDocuments: refetchSection === "docs",
        getLabs: refetchSection === "labs"
      }
    })
  }

  return (
    <>
      <ErrorBoundary
        fallback={
          <FallbackMenu>
            <Box color={(theme) => theme.palette.error.main}>Failed to load</Box>
          </FallbackMenu>
        }
      >
        <Suspense
          fallback={
            <FallbackMenu>
              <LoadingSpinner label={null} />
            </FallbackMenu>
          }
        >
          <SubsectionMenu
            header={<InboxHeader onUserRoleChange={onUserRoleChange} onRefetch={onRefetch} />}
            routes={getRoutes().map(r => <InboxRoute route={r.route} text={r.text} icon={r.icon} count={getCounts(r.text)} countLoading={loading || networkStatus === NetworkStatus.refetch} />)}
            footer={<InboxRoute route={"import"} text={"Import documents"} icon={ <UploadFileIcon /> } />}
          />
        </Suspense>
      </ErrorBoundary>
    </>
  )
}

const FallbackMenu = ({ children }) => {
  return (
    <Section.Column
      rightPadding={1}
    >
      {children}
    </Section.Column>
  )
}

const InboxHeader = ({onUserRoleChange, onRefetch})=>{
  return (
    <Box display={'flex'} >
      <UserRoleSelect onChange={onUserRoleChange} sx={{flex:"1 1 100%"}}/>
      <IconButton icon={<RefreshIcon />} onClick={onRefetch}/>
    </Box>
  )
}
