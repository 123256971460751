import React from "react";
import { Checkbox, MenuItem } from "@mui/material";
import { AutocompleteBase as Autocomplete } from "saga-library/src/components/Autocomplete";
import { Chip } from "../Chip";
import { SimpleTextField } from "../TextField";
import { Typography } from "../Typography";
import { FieldError } from "saga-library/src/components/Form";
import { useTheme } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

interface MultiSelectProps {
  options: {
    id: string | null
    name: string
    [option: string]: any
  }[]
  value?: any[]
  disabled?: boolean
  onChange: (any)=>void
  placeholder?: string
  selectAllId?: string
  selectAllPlaceholder?: string
  responsiveHeight?: boolean
  error?: FieldError
  helperText?: string
  dataTestId?: string
}

export const MultiSelect = ({
  options,
  value=[],
  disabled,
  onChange,
  placeholder = '',
  selectAllId = 'selectAllId',
  selectAllPlaceholder = 'Select all',
  responsiveHeight = false,
  error,
  helperText,
  dataTestId
}: MultiSelectProps) => {
  const theme = useTheme()

  const componentsProps = {
    paper:{
      sx: {
        "& .MuiAutocomplete-option[aria-selected='true']": {
          backgroundColor: `${theme.palette.backgrounds.selected} !important`,
        },
        "& .MuiAutocomplete-option[aria-selected='true']:hover": {
          backgroundColor: `${theme.palette.backgrounds.selectedHover} !important`,
        },
        "& .MuiAutocomplete-option[aria-selected='false']:hover": {
          backgroundColor: `${theme.palette.backgrounds.hover} !important`
        }
      }
    },
    clearIndicator: {
      sx: {
        '&:hover': {
          backgroundColor: theme.palette.backgrounds.hover,
        },
      }
    },
    popupIndicator: {
      sx: {
        '&:hover': {
          backgroundColor: theme.palette.backgrounds.hover,
        },
      }
    },
  }

  return <Autocomplete
    componentsProps={componentsProps}
    data-testid={dataTestId}
    renderInput={ params => {
      return <SimpleTextField
        {...params}
        dataTestId={`${dataTestId}-textfield`}
        error={!!error}
        helperText={helperText}
        placeholder={value.length > 0 ? "" : placeholder}
        multiline={responsiveHeight}
        InputProps={{
          ...params.InputProps,
          sx: { maxHeight: responsiveHeight ? '' : '40px' }
        }}
      />
    }}
    disableCloseOnSelect
    autoSelect={false}
    tabAutoSelect={false}
    multiple
    limitTags={responsiveHeight ? undefined : 1}
    options={options}
    getOptionLabel={ (option) => option.name }
    isOptionEqualToValue={ (option, value) => option.id === value.id }
    renderOption={(props, option, {selected, index}) =>
      <MultiSelectMenuItem dataTestId={`${dataTestId}-menuItem-${index}`} name={option.name} value={option.id} selected={selected} props={props}/>
    }
    disabled={disabled}
    onChange={(e,v) => onChange(v)}
    value={value}
    renderTags={(tagValues, getTagProps) => {
      const allSelected = tagValues.map(v => v.id).includes(selectAllId)
      if (allSelected) {
        const index = tagValues.findIndex(v => v.id === selectAllId)
        return (
          <Chip
            data-testid={`${dataTestId}-chip-${index}`}
            label={selectAllPlaceholder}
            size={'small'}
            {...getTagProps({index})}
          />
        )
      } else if (!responsiveHeight) {
        return (
          <>
            {tagValues.slice(0, 1).map((option, index) => (
              <Chip
                data-testid={`${dataTestId}-chip`}
                label={option.name}
                size={'small'}
                {...getTagProps({index})}
              />
            ))}
            {tagValues.length > 1 && `+${tagValues.length - 1}`}
          </>
        )
      } else {
        return (
          tagValues.map((option, index) => (
            <Chip
              data-testid={`${dataTestId}-chip-${index}`}
              label={option.name}
              size={'small'}
              {...getTagProps({index})}
            />
          ))
        )
      }
    }}
    clearIcon={<ClearIcon fontSize="small" data-testid={`${dataTestId}-clearIcon`} />}
    popupIcon={<ArrowDropDownIcon data-testid={`${dataTestId}-dropDownIcon`} />}
  />
}

const MultiSelectMenuItem = ({dataTestId, name, value, selected=false, props}) => {
  return  <MenuItem data-testid={dataTestId} key={name} value={value} {...props}>
    <Checkbox data-testid={`${dataTestId}-checkbox`} checked={selected} />
    <Typography> { name }</Typography>
  </MenuItem>
}

export default MultiSelect
