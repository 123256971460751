import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'saga-library/src/components/Form'
import { usePrompt } from '../../../../providers/NavigationPrompt'
import { TaskForm } from './TaskForm'
import { taskDefaults } from './TaskDefaultValues'
import { schema } from './TaskValidationsSchema'
import { LoadingButton, Section } from 'saga-library/src'
import { TaskAssignmentInput } from '../../../../types/tasks/TaskAssignment'
import { useTaskContext } from '../../../../providers/TaskProvider'
import { useNavigate, useParams } from 'react-router-dom'
import { useAccountContext } from '../../../../providers/AccountContext'
import { useUpdateCount } from '../../../../hooks/useUpdateCount'
import { flushSync } from 'react-dom'
import { useReviewContext } from '../../providers/ReviewProvider'
import { TaskCategory } from '../../../../types/tasks/TaskState'

const FORM_NAME = "new_task_form"

export const NewTask = ({}) => {
  const { enableNavigationPrompt, clearNavigationPrompt } = usePrompt()
  const { buildTenantRoute } = useAccountContext()
  const { createTask } = useTaskContext()
  const { taskFilters } = useReviewContext()
  const { updateTaskCount } = useUpdateCount()
  const navigate = useNavigate()
  const { user_id, role_id, tenant_id } = useParams()

  const formMethods = useForm<TaskAssignmentInput>({
    defaultValues: taskDefaults,
    schema: schema,
  })

  const {
    handleSubmit,
    formState: { dirtyFields, isSubmitting }
  } = formMethods

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, `tasks/t/new`)
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [Object.keys(dirtyFields).length])

  const onCreateComplete = (data) => {
    flushSync(() => {
      clearNavigationPrompt(FORM_NAME)
    })

    const isAssignedTo = (role_id && data.assignedRoleId === role_id) || (user_id && data.assignedUserId === user_id)
    const matchesFilterType = taskFilters.type === "*" || taskFilters.type === data.type.id
    const isIncomplete = data.state.category
    const matchesFilterState = taskFilters.state === data.state.id || (taskFilters.state === TaskCategory.INCOMPLETE && isIncomplete)

    if (isAssignedTo && isIncomplete) {
      updateTaskCount(1)
    }

    if (isAssignedTo && matchesFilterType && matchesFilterState) {
      navigate(buildTenantRoute(`inbox/${role_id ? `r/${role_id}` : `u/${user_id}`}/tasks/t/${data.id}`, tenant_id))
    } else {
      navigate(buildTenantRoute(`inbox/${role_id? `r/${role_id}`: `u/${user_id}`}/tasks/`, tenant_id))
    }
  }

  const onSave = handleSubmit(async (data) => {
    await createTask(data, onCreateComplete, { tenant_id, user_id, role_id })
  })

  return (
    <Section.Column
      rightPadding={1}
      sx={{ flex: "1 1 100%" }}
      headerLabel={"New Task"}
      headerProps={{
        primaryAction: <LoadingButton
          name={'new-task-save'}
          dataTestId={'new-task-save'}
          variant={'contained'}
          type={"submit"}
          form={FORM_NAME}
          loading={isSubmitting}
        >
          Save
        </LoadingButton>
      }}>
      <FormProvider {...formMethods}>
        <TaskForm
          formName={FORM_NAME}
          onSubmit={onSave}
          dataTestId={"task-editor-form"}
        />
      </FormProvider>
    </Section.Column>
  )
}