import { gql } from "@apollo/client";

export const EVENT_FRAGMENT = gql`
    fragment EventFragment on ScheduleEvent {
        id
        title
        start
        end
        allDay
        version
        itemDate
        scheduleId
        blockType
        resource: scheduleId
    }
`

export const UPDATE_EVENT = gql`
    ${EVENT_FRAGMENT}
    mutation UpdateEvent($tenantId: ID!, $id: ID! $input: ScheduleBlockInput!) {
        tenant(id: $tenantId) {
            schedule {
                updateEvent(id: $id, input: $input) {
                    ...EventFragment
                }
            }
        }
    }
`

export const DELETE_EVENT = gql`
  mutation DeleteEvent($tenantId: ID!, $id: ID!, $version: String!) {
    tenant(id: $tenantId) {
      schedule {
        deleteEvent(id: $id, version: $version)
      }
    }
  }
`


export const ADD_NEW_EVENT = gql`
    ${EVENT_FRAGMENT}
    mutation CreateEvent($tenantId: ID!, $input: ScheduleBlockInput!) {
        tenant(id: $tenantId) {
            schedule {
                createEvent(input: $input)    {
                    ...EventFragment
                }             
            }
        }
    }
`
