import { gql } from "@apollo/client";


export const BOOKING_PREFERENCE_FRAGMENT = gql`
  fragment BookingPreferenceFragment on BookingPreference {
    id
    blockType
    end
    itemDate
    scheduleId
    start
    title
    version
    resource: scheduleId
    appointmentTypes {
      id
      name
    }
  }
`

export const UPDATE_BOOKING_PREFERENCE = gql`
  ${BOOKING_PREFERENCE_FRAGMENT}
  mutation UpdateBookingPreference($tenantId: ID!, $scheduleBlockId: ID!, $scheduleBlockInput: ScheduleBlockInput!) {
    tenant(id: $tenantId) {
      schedule {
        updateBookingPreference(id: $scheduleBlockId, input: $scheduleBlockInput) {
          ...BookingPreferenceFragment
        }
      }
    }
  }
`

export const DELETE_BOOKING_PREFERENCE = gql`
  mutation DeleteBookingPreference($tenantId: ID!, $scheduleBlockId: ID!, $version: String!) {
    tenant(id: $tenantId) {
      schedule {
       deleteBookingPreference(id: $scheduleBlockId, version: $version) 
       }
    }
  }
`


export const ADD_NEW_BOOKING_PREFERENCE = gql`
  ${BOOKING_PREFERENCE_FRAGMENT}
  mutation CreateBookingPreference($tenantId: ID!, $input: ScheduleBlockInput!) {
    tenant(id: $tenantId) {
      schedule {
        createBookingPreference(input: $input) {
          ...BookingPreferenceFragment
        }
      }
    }
  }
`
