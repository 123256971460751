import React, { Suspense, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { TaskList } from './components/Tasks/TaskList'
import { FormSection } from './components/Tasks/FormSection'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { LoadingSpinner } from '../../components/LoadingScreen'
import { EmptyList, Section } from 'saga-library/src'
import { TaskListHeader } from './components/Tasks/TasksHeader'
import { useAccountContext } from '../../providers/AccountContext'
import { TaskMultipleSelected } from './components/Tasks/TaskMultipleSelected'
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined'
import { useReviewContext } from './providers/ReviewProvider'

export const EmptyTaskSelection = () => {
  return (
    <Section.Column rightPadding={1} sx={{ flex: "1 1 100%" }}>
      <EmptyList icon={AssignmentTurnedInOutlinedIcon} message={'Select a task to view it.'} />
    </Section.Column>
  )
}

export const Tasks = () =>{
  const { user_id, role_id, tenant_id } = useParams()
  const { taskFilters, loadTasks, refetchTasks, tasksRef } = useReviewContext()
  const { buildTenantRoute } = useAccountContext()

  const navigate = useNavigate()
  const [ selectedTasks, setSelectedTasks ] = useState<string[]>([])
  const [ clearSelectedTasks, setClearSelectedTasks ]  = useState<boolean>(false)
  const [ nextTaskId, setNextTaskId ] = useState<string|undefined>()

  useEffect(() => {
    if(!tenant_id && (!user_id || !role_id)) return

    setClearSelectedTasks(true)

    loadTasks({
      tenantId: tenant_id,
      userId: user_id,
      roleId: role_id,
    })
  }, [user_id, role_id, tenant_id])

  useEffect(() => {
    setClearSelectedTasks(true)
  }, [taskFilters])

  const navigateToNextTask = () => {
    if (!!nextTaskId) {
      navigate(buildTenantRoute(`inbox/${role_id? `r/${role_id}`: `u/${user_id}`}/tasks/t/${nextTaskId}`, tenant_id))
    } else {
      navigate(buildTenantRoute(`inbox/${role_id? `r/${role_id}`: `u/${user_id}`}/tasks/`, tenant_id))
    }
    refetchTasks()
  }

  const onSuccess = () => {
    setClearSelectedTasks(true)
    navigateToNextTask()
  }

  const view = () => {
    if (selectedTasks.length > 0) {
      return (
        <TaskMultipleSelected
          selectedTasks={selectedTasks}
          onSuccess={onSuccess}
          onCancel={() => setClearSelectedTasks(true)}
        />
      )
    }

    return <Outlet />
  }

  const onNewTask = () => {
    navigate(buildTenantRoute(`inbox/${role_id? `r/${role_id}`: `u/${user_id}`}/tasks/t/new`, tenant_id))
  }

  return (
    <Box display={'flex'} width={"100%"}>
      <Section.Column
        width={"50%"}
        sx={{pr:1}}
        header={<TaskListHeader onNewTask={onNewTask} />}
      >
        <Suspense fallback={<LoadingSpinner />}>
          {tasksRef && (
            <TaskList
              setSelectedTasks={setSelectedTasks}
              clearSelectedTasks={clearSelectedTasks}
              setClearSelectedTasks={setClearSelectedTasks}
              setNextTaskId={setNextTaskId}
            />
          )}
        </Suspense>
      </Section.Column>
      <FormSection>
        {view()}
      </FormSection>
    </Box>
  )
}
