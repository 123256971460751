import React from 'react'
import { Box } from '@mui/material'
import { Typography } from 'saga-library/src'
import { useFormattedDate } from '../../../hooks/FormattedDate'
import { useFormContext } from 'saga-library/src/components/Form'
import { userDisplayName } from 'saga-library/src/util/formatting'

export const ClaimInfoBase = () => {
  const { getValues } = useFormContext()

  return (
    <>
      <Box>
        <Typography variant={'body2'} color={(theme) => theme.palette.greys.medium}>
          Creator
        </Typography>
        <Typography variant={'body1'}>
          {userDisplayName(getValues('audit.creUserFirstName'), getValues('audit.creUserLastName')) || "Unknown"}
        </Typography>
      </Box>
      <Box>
        <Typography variant={'body2'} color={(theme) => theme.palette.greys.medium}>
          Date and time created
        </Typography>
        <Typography variant={'body1'}>
          {useFormattedDate(getValues('audit.crePit'), true)}
        </Typography>
      </Box>
    </>
  )
}

export const ClaimInfo = () => {
  return (
    <Box display={'flex'} flexDirection={'column'} gap={3}>
      <ClaimInfoBase />
    </Box>
  )
}