import { useParams } from 'react-router-dom'
import { useSuspenseQuery } from '@apollo/client'
import { GET_PATIENT_OTHER_DOCUMENTS } from '../../../../../graphql-definitions'
import { parseLinkedDocumentQueryResults } from '../../../../patients/providers/PatientDocumentProvider'
import { EmptyList } from 'saga-library/src'
import { LinkedDocumentType } from '../../../../../types/Document'
import moment from 'moment-timezone'
import { displayCategory } from '../../../../patients/util/displayEnum'
import React from 'react'
import { DateListItem, ItemType, ListBox } from '../LinkItem'

export const OtherDocumentsList = ({patientId, onSelect}) => {
  const { tenant_id } = useParams()

  const { data } = useSuspenseQuery(GET_PATIENT_OTHER_DOCUMENTS , {
    variables: {
      patientId: patientId,
      tenantId: tenant_id
    }
  })

  const otherDocuments = parseLinkedDocumentQueryResults(data)

  if (otherDocuments.length > 0) {
    return (
      <ListBox>
        {otherDocuments.map(d => <OtherDocumentItem item={d} onClick={onSelect} key={`link_patient_other_document_${d.id}`}/>)}
      </ListBox>
    )
  }

  return (
    <EmptyList sx={{ p: 1, width: "200px" }} message={`No associated other documents`} />
  )
}

export const OtherDocumentItem = ({item, onClick, icon, ...itemTypeProps}:ItemType) => {
  const otherDocument = item as LinkedDocumentType

  return (
    <DateListItem
      date={ moment(otherDocument.documentDate).toDate()}
      label={otherDocument.description || displayCategory(otherDocument.category)}
      onClick={onClick ? (e)=>onClick(e, otherDocument) : undefined}
      icon={icon}
      {...itemTypeProps}
    />
  )
}