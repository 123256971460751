import { Button, Section, Typography } from 'saga-library/src'
import React, { useState } from 'react'
import { Box, createSvgIcon, Divider } from '@mui/material'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import { ReactComponent as RecentSVG } from '../../../../assets/RecentIcon.svg'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import { MarkDocumentReviewedDialog } from './MarkDocumentReviewedDialog'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import { useUpdateCount } from '../../../../hooks/useUpdateCount'

const RecentIcon = createSvgIcon(<RecentSVG />, "Update")

type DocumentsMultipleSelectedProps = {
  selectedDocuments: string[]
  clearSelected: () => void
  onSuccess: () => void
}

export const DocumentsMultipleSelected = ({
  selectedDocuments = [],
  clearSelected,
  onSuccess,
}: DocumentsMultipleSelectedProps) => {
  const { showSuccessAlert } = useAlerts()
  const { updateDocumentCount } = useUpdateCount()
  const [ openDialog, setOpenDialog ] = useState<boolean>(false)

  const handleOnSuccess = () => {
    onSuccess()
    showSuccessAlert(`${selectedDocuments.length} document(s) have been reviewed.`)
    updateDocumentCount(-selectedDocuments.length)
  }

  return (
    <>
      <Section.Column rightPadding={1} sx={{ flex: "1 1 100%" }}>
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} gap={4} height={"100%"}>
          <Box display={"flex"} flexDirection={"inherit"} alignItems={"inherit"}>
            <DescriptionOutlinedIcon sx={{ color: (theme) => theme.palette.greys.medium, fontSize: "88px" }} />
            <Typography variant={"h2"} dataTestId={'document-selected-count'}>{selectedDocuments.length} document(s) for review</Typography>
          </Box>
          <Box display={"flex"} flexDirection={"inherit"} gap={2} width={"220px"}>
            <Button
              name={"mark-document-reviewed"}
              variant={"text"}
              startIcon={<RecentIcon />}
              onClick={() => setOpenDialog(true)}
              dataTestId={"mark-document-reviewed-button"}
            >
              MARK AS REVIEWED
            </Button>
            <Divider sx={{ borderColor: "rgba(32, 41, 49, 0.32)" }} />
            <Button
              name={"cancel-document-selection"}
              variant={"text"}
              startIcon={<ClearOutlinedIcon />}
              onClick={clearSelected}
              dataTestId={"cancel-document-selection-button"}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Section.Column>
      <MarkDocumentReviewedDialog
        open={openDialog}
        onSuccess={handleOnSuccess}
        selectedDocuments={selectedDocuments}
        onClose={() => setOpenDialog(false)}
      />
    </>
  )
}