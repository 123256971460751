import React, { useEffect } from 'react'
import { Box } from "@mui/material";
import { TableList, Typography, TableListHeaderConfig } from 'saga-library/src'
import { useParams } from 'react-router-dom'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import { useLazyQuery } from '@apollo/client'
import _get from 'lodash/get'
import { PatientClaimRow } from './PatientClaimRow'
import { AbClaim } from "../../../types/billing"
import { LIST_PATIENTCLAIMS } from "../../../graphql-definitions";
import { useUnsubmittedClaimsContext } from '../providers/UnsubmittedClaimsProvider'
import { useAccountContext } from '../../../providers/AccountContext'
import { DATE_FORMAT } from '../../../utils/SettingsConstants'

const columns: TableListHeaderConfig[] = [
  { name: 'Service date' },
  { name: 'Service' },
  { name: 'Calls' },
  { name: 'Diagnoses' },
  { name: 'Practitioner' },
  { name: 'Claimed' },
  { name: 'Action' },
  { name: 'Status' },
  { name: 'Paid' },
  { name: 'Referral practitioner' },
]

export const PatientClaimsList = () => {
  const { tenant_id } = useParams()
  const { showErrorAlert } = useAlerts()
  const { getUserSetting } = useAccountContext()
  const dateFormat = getUserSetting(DATE_FORMAT) as string
  const { currentPatientId: patientId } = useUnsubmittedClaimsContext()

  const [runQuery, { data, loading }] = useLazyQuery(LIST_PATIENTCLAIMS, {
    variables: { tenantId: tenant_id, selectedPatientId: patientId },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
      showErrorAlert('Patient claims list couldn\'t be retrieved.')
    },
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (patientId) {
      runQuery()
    }
  }, [patientId])

  const patientClaims: AbClaim[] = _get(
    data,
    'tenant.abClaim.listAbClaimForPatient',
    null
  )

  return (
    <Box display={'flex'} flexDirection={'column'} height={'100%'}>
      <Typography variant={'h2'}>Patient claims</Typography>

      <TableList
        size={'small'}
        loading={loading}
        emptyListComponent={ { message: "Select a patient to see their recent claims." } }
        columns={columns}
        sx={{minHeight:"90px"}}
        rows={
          patientClaims
            ? [...patientClaims.map((claim) => PatientClaimRow(claim, dateFormat))]
            : []
        }
      />
    </Box>
  )
}

export default PatientClaimsList

