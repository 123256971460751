import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Alert, AlertTitle, Box } from '@mui/material'
import { Tabs, Button, Form, IconButton, RemoveButton, SaveButton } from "saga-library/src";
import { ClaimBase } from "../ClaimBase";
import { ClaimOptions } from "../ClaimOptions";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { useMutation, useReadQuery } from '@apollo/client'
import { useNavigate, useParams } from "react-router-dom";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { schema } from "../../util/baseClaimValidation";
import _get from "lodash/get";
import { useAccountContext } from "../../../../providers/AccountContext";
import { HoldIcon, ReadyIcon } from '../ClaimStatusIcons'
import { ClaimInputType } from "../../../../types/billing";
import { EditUnsubmittedClaimHeader } from "./EditUnsubmittedClaimHeader";
import { setAbClaimValues } from "../../util/setAbClaimValues";
import { DELETE_CLAIM, HOLD_CLAIM, LIST_UNSUBMITTEDCLAIMS, UNHOLD_CLAIM } from "../../../../graphql-definitions";
import { createClaimInputFromFormData } from "../../util/createClaimInputFromFormData";
import { setProblems } from "../../util/setProblems";
import { usePrompt } from "../../../../providers/NavigationPrompt";
import { Permission, PermissionType } from "../../../../types/settings/Permission";
import { AbClaimStatus } from "../../../../utils/ABClaimConstants";
import { ReadOnlyFormActionsMask } from "../../../../components/ReadOnlyFormActionsMask";
import claimDefaults from "../../util/ClaimDefaults"
import { useUnsubmittedClaimsContext } from '../../providers/UnsubmittedClaimsProvider'
import { withMultipleSelectedHOC } from './UnsubmittedMultipleSelected'
import { ClaimInfo } from '../ClaimInfo'


const FORM_NAME = "unsubmitted_claim_update_details"


export const EditUnsubmittedClaim = withMultipleSelectedHOC(() => {
  const { tenant_id, claim_id } = useParams()
  const { getClaim, getClaimQueryRef } = useUnsubmittedClaimsContext()

  useEffect(() => {
    getClaim({
      claimId: claim_id,
      tenantId: tenant_id,
    })
  }, [claim_id, tenant_id])

  return getClaimQueryRef == null ? <></> : <EditUnsubmittedClaimView getClaimQueryRef={getClaimQueryRef} />
})



export const EditUnsubmittedClaimView = ({getClaimQueryRef}) => {
  const { tenant_id, claim_id } = useParams()
  const { showErrorAlert, showSuccessAlert } = useAlerts()
  const navigate = useNavigate()
  const { buildTenantRoute } = useAccountContext()
  const { enableNavigationPrompt } = usePrompt()
  const {setCurrentPatientId, updateClaim, setRedirectToNewlyCreatedClaim} = useUnsubmittedClaimsContext()

  const formRef = useRef<HTMLFormElement>(null)

  const formMethods = useForm<ClaimInputType>({
    defaultValues: claimDefaults,
    schema: schema
  })
  const {
    getValues,
    handleSubmit,
    formState: { dirtyFields, isSubmitSuccessful, isSubmitting },
    resetField,
    reset,
    setError,
    watch,
    setFocus
  } = formMethods

  const data = useReadQuery(getClaimQueryRef!)
  const claim = _get(data, 'data.tenant.abClaim.abClaim', null) as any
  const problems = _get(data, 'data.tenant.abClaim.abClaim.problems', []) as any
  const version = claim.version

  setProblems(problems, setError)

  useEffect(() => {
    let patientId = claim?.patient?.id || ''
    setCurrentPatientId(patientId)
    setAbClaimValues(claim, reset)
  }, [claim, problems, version])

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME)
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [Object.keys(dirtyFields).length]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true })
    }
  }, [isSubmitSuccessful])

  React.useEffect(() => {
    const subscription = watch((data, { name, type }) => {
      // Reset fee modifier input on SC change.
      if (name === "serviceCode" && type === "change") {
        resetField("serviceCodeFeeModifiers")
        resetField("feeMod1Units")
        resetField("feeMod2Units")
        resetField("feeMod3Units")
      }
      // Update patient for patient claims list
      let patientId = data?.patient?.id || ''
      setCurrentPatientId(patientId)
    })
    return () => subscription.unsubscribe()
  }, [watch])


  const onSubmit = handleSubmit(async(updateData) => {
    let cleanedUpdateData = createClaimInputFromFormData(updateData)
    await updateClaim(claim_id, cleanedUpdateData)
    setAbClaimValues(updateData, reset)
  })

  const save = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      )
    }
  }

  const shortcuts = useMemo(() => ({
    "d": () => {
      save()
    },
    "Enter": () => {
      save()
    },
    "l": () => {
      save()
    },
    "f": () => {
      setFocus("facility")
    },
    "r": () => {
      setFocus("referralPractitioner")
    },
    "t": () => {
      setFocus("text")
    },
    "p": () => {
      setFocus("patient")
    },
    "e": () => {
      setFocus("encounter")
    }
  }), [setFocus])

  const handleKeyPress = useCallback((event) => {
    if (event.altKey && event.key in shortcuts){
      event.preventDefault()
      shortcuts[event.key]()
    }
  }, [setFocus])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    };
  }, [handleKeyPress])

  const [ holdClaim ] = useMutation(HOLD_CLAIM, {
    variables: {
      claimId: claim_id,
      tenantId: tenant_id,
      version: getValues('version')
    },
    onCompleted: (data) => {
      const claim = _get(data, 'tenant.abClaim.holdClaim', null)
      setAbClaimValues(claim, reset)
      showSuccessAlert("Claim has been held.")
    },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
      showErrorAlert("Claim couldn't be held.")
    },
  })

  const [ unholdClaim ] = useMutation(UNHOLD_CLAIM, {
    variables: {
      claimId: claim_id,
      tenantId: tenant_id,
      version: getValues('version')
    },
    onCompleted: (data) => {
      const claim = _get(data, 'tenant.abClaim.unholdClaim', null)
      setAbClaimValues(claim, reset)
      showSuccessAlert("Claim has been unheld.")
    },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
      showErrorAlert("Claim couldn't be unheld.")
    },
  })

  const [ deleteClaim ] = useMutation(DELETE_CLAIM, {
    variables: {
      claimId: claim_id,
      tenantId: tenant_id,
      version: getValues('version')
    },
    onCompleted: (data) => {
      setRedirectToNewlyCreatedClaim(false)
      showSuccessAlert("Claim has been deleted.")
      navigate(buildTenantRoute('billing', tenant_id))
    },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
      showErrorAlert("Claim couldn't be deleted.")
    },
    update(cache, returnedData, {variables}) {
      cache.updateQuery({
        query: LIST_UNSUBMITTEDCLAIMS,
        variables: {tenantId: tenant_id} },
        (data) => {
          let list = [...data.tenant.abClaim.listUnsubmittedAbClaim.filter(c => c.id !== claim_id)]
          return {
            tenant: {
              abClaim: {
                listUnsubmittedAbClaim: list
              }
            }
          }
        }
      )
    }
  })

  const options = [
    {
      label: 'BASE',
      key: 'BASE',
      content: (
        <ClaimBase
          isAssessed={false}
        />
      ),
    },
    {
      label: 'OPTIONS',
      key: 'OPTIONS',
      content: (
        <ClaimOptions.Form />
      )
    },
    {
      label: 'INFO',
      key: 'INFO',
      content: (
        <ClaimInfo />
      )
    }
  ]

  const hasErrors = getValues('claimStatus') === AbClaimStatus.DRAFT
  const isReady = getValues('claimStatus') === AbClaimStatus.READY

  const UpdateClaimHeader = () => {
    return (
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <EditUnsubmittedClaimHeader identifier={getValues('claimIdentifier')} status={getValues('claimStatus')}/>
        <ReadOnlyFormActionsMask requiredType={PermissionType.Billing} requiredPermission={Permission.READWRITE}>
          <Box display={'flex'} alignItems={'center'} gap={1}>
            <Box>
              <HoldButton
                holdClaim={holdClaim}
                unholdClaim={unholdClaim}
                hasErrors={hasErrors}
                isReady={isReady}
                dataTestId={'unsubmitted-claim-hold-button'}
              />
              <RemoveButton
                onClick={deleteClaim}
                dataTestId={'unsubmitted-claim-delete-button'}
              />
            </Box>
            <Button
              name="newClaim"
              variant={'outlined'}
              onClick={() => {
                setRedirectToNewlyCreatedClaim(false)
                navigate(buildTenantRoute('billing', tenant_id))
              }}
            >
              NEW CLAIM
            </Button>
            <SaveButton form={FORM_NAME} submitting={isSubmitting} dataTestId={'unsubmittedClaimUpdate'} />
          </Box>
        </ReadOnlyFormActionsMask>
      </Box>
    )
  }

  return (
    <FormProvider {...formMethods}>
      <UpdateClaimHeader />
      <Box display={'flex'} flexDirection={'column'}>
        <Form onSubmit={onSubmit} autoComplete={'false'} ref={formRef} id={FORM_NAME}>
          {problems.some(p => p.field === 'claim' && p.severity === 'WARNING') &&
            <Alert severity="warning">
              <AlertTitle>Warning</AlertTitle>
              {problems.filter(p => p.field === 'claim' && p.severity === 'WARNING').map( p => <>{p.message}<br/></> )}
            </Alert>
          }
          {problems.some(p => p.field === 'claim' && p.severity === 'ERROR') &&
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {problems.filter(p => p.field === 'claim' && p.severity === 'ERROR').map( p => <>{p.message}<br/></> )}
            </Alert>
          }
          <Tabs options={options} sx={{ my: 1 }} dataTestId={'edit-unsubmitted-claim'} />
        </Form>
      </Box>
    </FormProvider>
  )
}

export const HoldButton = ({ holdClaim, unholdClaim, hasErrors, isReady, dataTestId }) => {
  if(hasErrors) return null

  const isHeld = !hasErrors && !isReady
  return (
    <IconButton
      name={'hold-claim'}
      tooltip={isHeld ? 'Set claim as ready to submit' : 'Hold claim'}
      icon={isHeld ? <ReadyIcon sx={{ color: (theme) => theme.palette.greys.medium }} /> : <HoldIcon />}
      onClick={isHeld ? unholdClaim : holdClaim}
      dataTestId={dataTestId}
    />
  )
}
