import React from 'react'
import { SvgIconProps } from '@mui/material'
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline'
import PauseCircleOutline from '@mui/icons-material/PauseCircleOutline'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import RepeatIcon from '@mui/icons-material/Repeat'

export const ReadyIcon = (props: SvgIconProps) => (
  <CheckCircleOutline sx={{ color: (theme) => theme.palette.primary.main }} {...props} />
)

export const HoldIcon = (props: SvgIconProps) => (
  <PauseCircleOutline sx={{ color: (theme) => theme.palette.greys.medium }} {...props} />
)

export const ErrorIcon = (props: SvgIconProps) => (
  <WarningAmberIcon sx={{ color: (theme) => theme.palette.warning.main }} {...props} />
)

export const ResubmittedIcon = (props: SvgIconProps) => (
  <RepeatIcon sx={{ color: (theme) => theme.palette.greys.dark }} {...props} />
)