import React from "react";
import { useParams } from "react-router-dom";
import { useMutation, useBackgroundQuery, QueryReference } from "@apollo/client";
import {
  ACTIVATE_PRESCRIPTION,
  ACTIVATE_SELECTED_PRESCRIPTIONS,
  INACTIVATE_PRESCRIPTION,
  CREATE_ACTIVE_PRESCRIPTION,
  CREATE_PRESCRIPTION,
  DELETE_PRESCRIPTION,
  UPDATE_DRAFT_PRESCRIPTION,
  CREATE_HISTORICAL_PRESCRIPTION,
  ARCHIVE_PRESCRIPTION,
  RENEW_PRESCRIPTION,
  RENEW_ALL_ACTIVE_CONTINUOUS_PRESCRIPTIONS,
  RENEW_SELECTED_PRESCRIPTIONS,
  UNFAVOURITE_PRESCRIPTION,
  INCREMENT_FAVOURITE_PRESCRIPTION
} from "../../../graphql-definitions";


import omitDeep from 'omit-deep-lodash'
import { useAlerts } from "saga-library/src/providers/Alerts";
import _get from "lodash/get";
import { PrescriptionStatus, PrescriptionType } from "../../../types/Prescription";
import {
  cleanPrescriptionInput,
  PrescriptionSortPredicate
} from '../components/prescriptions/components/PrescriptionUtil'
import { GET_PATIENT_PRESCRIPTIONS } from "../../../graphql-definitions/tenant/patient/PrescriptionQueries";

const getPrescriptionQueryResults = (data) => {
  return [..._get(data, 'tenant.patient.prescription.list', []) as Array<PrescriptionType>]
}

export const parsePrescriptionQueryResults = (data) => {
  return getPrescriptionQueryResults(data).sort(PrescriptionSortPredicate)
}

interface PrescriptionContextInterface {
  createDraftPrescription: (prescription: PrescriptionType, onComplete: (prescription: PrescriptionType) => void) => void
  updateDraftPrescription: (prescription: PrescriptionType, onComplete: (prescription: PrescriptionType) => void) => void
  deleteDraftPrescription: (prescription: PrescriptionType, onComplete: () => void) => void
  renewPrescription: (prescriptionId: string, onComplete: (prescription: PrescriptionType) => void) => void
  renewAllActiveAndContinuousPrescriptions: (totalActiveAndContinuous: number, onComplete: (prescriptions: PrescriptionType[]) => void) => void
  renewSelectedPrescriptions: (selectedPrescriptions: PrescriptionType[], onComplete: (prescriptions: PrescriptionType[]) => void) => void
  activatePrescription: (prescription: PrescriptionType, onComplete: (prescription: PrescriptionType) => void) => void
  activateSelectedPrescriptions: (selectedPrescriptions: PrescriptionType[], onComplete: (prescriptions: PrescriptionType[]) => void) => void
  inactivatePrescription: (prescriptionId: string, onComplete: (prescription: PrescriptionType) => void) => void
  archivePrescription: (prescription: PrescriptionType, onComplete: (prescription: PrescriptionType) => void) => void
  unfavouritePrescription: (prescriptionId: string, onComplete: () => void) => void
  incrementFavouritePrescription: (prescriptionId: string) => void
  prescriptionsQueryRef: QueryReference | null
  getPrescriptionQueryResults: (any) => PrescriptionType[]
  parsePrescriptionQueryResults: (any) => PrescriptionType[]
}

const defaultPrescriptionContext: PrescriptionContextInterface = {
  createDraftPrescription: (prescription: PrescriptionType, onComplete: (prescription:PrescriptionType) => void) => null,
  updateDraftPrescription: (prescription: PrescriptionType, onComplete: (prescription:PrescriptionType) => void) => null,
  deleteDraftPrescription: (prescription: PrescriptionType, onComplete: () => void) => null,
  renewPrescription: (prescriptionId: string, onComplete: (prescription: PrescriptionType) => void) => null,
  renewAllActiveAndContinuousPrescriptions: (totalActiveAndContinuous: number,  onComplete: (prescription:PrescriptionType[]) => void) => null,
  renewSelectedPrescriptions: (selectedPrescriptions: PrescriptionType[],  onComplete: (prescription:PrescriptionType[]) => void) => null,
  activatePrescription: (prescription: PrescriptionType, onComplete: (prescription:PrescriptionType) => void) => null,
  activateSelectedPrescriptions: (selectedPrescriptions: PrescriptionType[], onComplete: (prescriptions:PrescriptionType[]) => void) => null,
  inactivatePrescription: (prescriptionId: string, onComplete: (prescription:PrescriptionType) => void) => null,
  archivePrescription: (prescription: PrescriptionType, onComplete: (prescription:PrescriptionType) => void) => null,
  unfavouritePrescription: (prescriptionId: string, onComplete: (prescription:PrescriptionType) => void) => null,
  incrementFavouritePrescription: (prescriptionId: string) => null,
  prescriptionsQueryRef: null,
  getPrescriptionQueryResults: getPrescriptionQueryResults,
  parsePrescriptionQueryResults: parsePrescriptionQueryResults
}

const PrescriptionContext = React.createContext(defaultPrescriptionContext)

export const PrescriptionProvider = ({ children }) => {
  const { tenant_id, patient_id } = useParams()
  const { showErrorAlert, showSuccessAlert, showWarningAlert } = useAlerts()

  const [prescriptionsQueryRef] = useBackgroundQuery(GET_PATIENT_PRESCRIPTIONS, {
    variables: {
      tenantId: tenant_id,
      patientId: patient_id
    }
  })

  const [createDraftPrescriptionGQL] = useMutation(CREATE_PRESCRIPTION)
  const [createActivePrescriptionGQL] = useMutation(CREATE_ACTIVE_PRESCRIPTION)
  const [createHistoricalPrescriptionGQL] = useMutation(CREATE_HISTORICAL_PRESCRIPTION)

  const [archivePrescriptionGQL] = useMutation(ARCHIVE_PRESCRIPTION)

  const [updateDraftPrescriptionGQL] = useMutation(UPDATE_DRAFT_PRESCRIPTION)
  const [deleteDraftPrescriptionGQL] = useMutation(DELETE_PRESCRIPTION)

  const [renewPrescriptionGQL] = useMutation(RENEW_PRESCRIPTION)
  const [renewAllPrescriptionGQL] = useMutation(RENEW_ALL_ACTIVE_CONTINUOUS_PRESCRIPTIONS)
  const [renewSelectedPrescriptionGQL] = useMutation(RENEW_SELECTED_PRESCRIPTIONS)

  const [activatePrescriptionGQL] = useMutation(ACTIVATE_PRESCRIPTION)
  const [activateSelectedPrescriptionsGQL] = useMutation(ACTIVATE_SELECTED_PRESCRIPTIONS)
  const [inactivatePrescriptionGQL] = useMutation(INACTIVATE_PRESCRIPTION)

  const [unfavouritePrescriptionGQL] = useMutation(UNFAVOURITE_PRESCRIPTION)
  const [incrementFavouritePrescriptionGQL] = useMutation(INCREMENT_FAVOURITE_PRESCRIPTION)

  const updatePrescriptionsCache = (cache, additions) => {
    cache.updateQuery({
      query: GET_PATIENT_PRESCRIPTIONS,
      variables: { tenantId: tenant_id, patientId: patient_id }
    }, (data) => {
      return {
        tenant: {
          patient: {
            prescription: {
              list: [
                ...data.tenant.patient.prescription.list,
                ...(Array.isArray(additions) ? additions : [additions])]
            }
          }
        }
      }
    })
  }

  const createDraftPrescription = async(prescription, onComplete) => {
    const newPrescription = cleanPrescriptionInput(prescription)

    await createDraftPrescriptionGQL({
      variables: {
        prescriptionInput: {
          ...newPrescription,
          patientId: patient_id,
        },
        tenantId: tenant_id,
      },
      onCompleted: (data) => {
        const prescription = data.tenant.patient.prescription.create
        onComplete(prescription)
        showSuccessAlert(`Prescription has been saved as a draft.`)
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert('Prescription couldn\'t be created.')
      },
      update: (cache, data) => {
        const newPrescription = _get(data, 'data.tenant.patient.prescription.create')
        updatePrescriptionsCache(cache, newPrescription)
      }
    })
  }

  const createActivePrescription = async(prescription, onComplete) => {
    const newPrescription = cleanPrescriptionInput(prescription)

    await createActivePrescriptionGQL({
      variables: {
        prescriptionInput: {
          ...newPrescription,
          patientId: patient_id,
        },
        tenantId: tenant_id,
      },
      onCompleted: (data) => {
        const newPrescription = data.tenant.patient.prescription.createActivePrescription
        onComplete(newPrescription)
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert('Prescription couldn\'t be created.')
      },
      update: (cache, data) => {
        const newPrescription = _get(data, 'data.tenant.patient.prescription.createActivePrescription')
        updatePrescriptionsCache(cache, newPrescription)
      }
    })
  }

  const updateDraftPrescription = async(prescription, onComplete) => {
    const prescriptionId = prescription.id
    const updatedPrescription = cleanPrescriptionInput(prescription)

    await updateDraftPrescriptionGQL({
      variables: {
        prescriptionId: prescriptionId,
        prescriptionInput: {
          ...updatedPrescription,
          patientId: patient_id,
        },
        tenantId: tenant_id,
      },
      onCompleted: (data) => {
        const prescription = data.tenant.patient.prescription.update
        onComplete(prescription)
        showSuccessAlert(`Prescription has been updated.`)
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert('Prescription couldn\'t be updated.')
      }
    })
  }

  const deleteDraftPrescription = async(prescription, onComplete) => {
    await deleteDraftPrescriptionGQL({
      variables: {
        tenantId: tenant_id,
        prescriptionId: prescription.id,
        version: prescription.version
      },
      onCompleted: (data) => {
        onComplete()
        showSuccessAlert(`Prescription has been deleted.`)
      },
      onError:(error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert('Prescription couldn\'t be deleted.')
      },
      update: (cache, data) => {
        const normalizedId = cache.identify({ id: prescription.id, __typename: 'Prescription' })
        cache.evict({ id: normalizedId })
        cache.gc()
      }
    })
  }

  const renewPrescription = async(prescriptionId, onComplete) => {
    await renewPrescriptionGQL({
      variables: {
        tenantId: tenant_id,
        prescriptionId: prescriptionId
      },
      onCompleted: (data) => {
        const prescription = omitDeep(data.tenant.patient.prescription.renew, '__typename')
        onComplete(prescription)
        showSuccessAlert(`Prescription has been renewed as a draft.`)
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert('Prescription couldn\'t be renewed.')
      },
      update: (cache, data) => {
        const renewedPrescription = _get(data, 'data.tenant.patient.prescription.renew')
        updatePrescriptionsCache(cache, renewedPrescription)
      }
    })
  }

  const renewAllActiveAndContinuousPrescriptions = async(totalActiveAndContinuous, onComplete) => {
    await renewAllPrescriptionGQL({
      variables: {
        tenantId: tenant_id,
        patientId: patient_id
      },
      update: (cache, res) => {
        const renewedPrescriptions:any[] = _get(res, 'data.tenant.patient.prescription.renewAllActiveAndContinuousPrescriptions', [])
        updatePrescriptionsCache(cache, renewedPrescriptions)
      },
      onCompleted: (data) => {
        const renewedPrescriptions = _get(data, 'tenant.patient.prescription.renewAllActiveAndContinuousPrescriptions', [])
        if (totalActiveAndContinuous === 0 && renewedPrescriptions.length === 0) {
          showWarningAlert('There are no active and continuous prescriptions to renew.')
        } else if (totalActiveAndContinuous === renewedPrescriptions.length) {
          showSuccessAlert(`All active and continuous prescriptions have been renewed as drafts.`)
          onComplete(renewedPrescriptions)
        } else {
          showErrorAlert('Prescriptions couldn\'t be renewed.')
        }
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert('Prescriptions couldn\'t be renewed.')
      },
    })
  }

  const renewSelectedPrescriptions = async(selectedPrescriptions, onComplete) => {
    const cleanedIds = selectedPrescriptions.filter((prescription) =>
      prescription?.status !== PrescriptionStatus.DRAFT && prescription?.renewedOn === null
    ).map((prescription) => prescription?.id) as number[]

    if (cleanedIds.length > 0) {
      await renewSelectedPrescriptionGQL({
        variables: {
          tenantId: tenant_id,
          prescriptionIds: cleanedIds
        },
        update: (cache, res) => {
          const renewedPrescriptions = _get(res, 'data.tenant.patient.prescription.renewSelectedPrescriptions', [])
          updatePrescriptionsCache(cache, renewedPrescriptions)
        },
        onCompleted: (data) => {
          const renewedPrescriptions = _get(data, 'tenant.patient.prescription.renewSelectedPrescriptions', [])
          if (cleanedIds.length === 0 && renewedPrescriptions.length === 0) {
            showWarningAlert('No prescriptions were selected to renew.')
          } else if (cleanedIds.length === renewedPrescriptions.length) {
            showSuccessAlert(`Selected prescriptions have been renewed as drafts.`)
          } else {
            showErrorAlert('Prescriptions couldn\'t be renewed.')
          }
          onComplete(renewedPrescriptions)
        },
        onError: (error) => {
          console.error(JSON.stringify(error, null, 2))
          showErrorAlert('Prescriptions couldn\'t be renewed.')
        },
      })
    } else {
      showWarningAlert("Select at least one active or inactive prescription to renew.")
    }
  }

  const activateExistingPrescription = async(prescription, onComplete) => {
    const prescriptionId = prescription.id
    const updatedPrescription = cleanPrescriptionInput(prescription)

    await activatePrescriptionGQL({
      variables: {
        prescriptionId: prescriptionId,
        renewedFromVersion: prescription.renewedFromVersion,
        prescriptionInput: {
          ...updatedPrescription,
          patientId: patient_id,
        },
        tenantId: tenant_id,
      },
      onCompleted: (data) => {
        const updatedPrescription = data.tenant.patient.prescription.activatePrescription
        onComplete(updatedPrescription)
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert('Prescription couldn\'t be updated.')
      }
    })
  }

  const createHistoricalPrescription = async(prescription, onComplete) => {
    const newPrescription = cleanPrescriptionInput(prescription)

    await createHistoricalPrescriptionGQL({
      variables: {
        prescriptionInput: {
          ...newPrescription,
          patientId: patient_id,
        },
        tenantId: tenant_id,
      },
      onCompleted: (data) => {
        const newPrescription = data.tenant.patient.prescription.createHistoricalPrescription
        onComplete(newPrescription)
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert('Prescription couldn\'t be created.')
      },
      update(cache, data) {
        const newPrescription = _get(data, 'data.tenant.patient.prescription.createHistoricalPrescription')
        updatePrescriptionsCache(cache, newPrescription)
      }
    })
  }

  const archiveExistingPrescription = async(prescription, onComplete) => {
    const prescriptionId = prescription.id
    const updatedPrescription = cleanPrescriptionInput(prescription)

    await archivePrescriptionGQL({
      variables: {
        prescriptionId: prescriptionId,
        prescriptionInput: {
          ...updatedPrescription,
          patientId: patient_id,
        },
        tenantId: tenant_id,
      },
      onCompleted: (data) => {
        const updatedPrescription = data.tenant.patient.prescription.archivePrescription
        onComplete(updatedPrescription)
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert('Prescription couldn\'t be updated.')
      }
    })
  }

  const activatePrescription = async(prescription, onComplete) => {
    const updatePrescriptionComplete = (prescription) => {
      showSuccessAlert(`Prescription has been set as active.`)
      onComplete(prescription)
    }

    if(prescription.id) {
      await activateExistingPrescription(prescription, updatePrescriptionComplete)
    } else {
      await createActivePrescription(prescription, updatePrescriptionComplete)
    }
  }

  const activateSelectedPrescriptions = async(selectedPrescriptions, onComplete) => {
    const cleanedPrescriptions = selectedPrescriptions.filter((prescription) => prescription.status === PrescriptionStatus.DRAFT)
    if (cleanedPrescriptions.length > 0) {
      await activateSelectedPrescriptionsGQL({
        variables: {
          tenantId: tenant_id,
          prescriptions: cleanedPrescriptions.map((prescription) => {
            return {
              item1: prescription.id as number,
              item2: cleanPrescriptionInput(prescription) as PrescriptionType
            }
          })
        },
        onCompleted: (data) => {
          const activatedPrescriptions = _get(data, 'tenant.patient.prescription.activateSelectedPrescriptions', [])
          if (cleanedPrescriptions.length === 0 && activatedPrescriptions.length === 0) {
            showWarningAlert('No draft prescriptions were selected to set as active.')
          } else if (cleanedPrescriptions.length === activatedPrescriptions.length) {
            showSuccessAlert(`Selected draft prescriptions have been set as active.`)
            onComplete(selectedPrescriptions)
          } else {
            showErrorAlert('Prescriptions couldn\'t be updated.')
          }
        },
        onError: (error) => {
          console.error(JSON.stringify(error, null, 2))
          showErrorAlert('Prescriptions couldn\'t be set as active.')
        },
      })
    } else {
      showWarningAlert("Select at least one draft prescription to set as active.")
    }
  }

  const inactivatePrescription = async(prescription, onComplete) => {
    await inactivatePrescriptionGQL({
      variables: {
        prescriptionId: prescription.id,
        version: prescription.version,
        tenantId: tenant_id,
      },
      onCompleted: (data) => {
        const updatedPrescription = _get(data, 'tenant.patient.prescription.inactivatePrescription', null)
        showSuccessAlert(`Prescription has been set as inactive.`)
        onComplete(updatedPrescription)
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert('Prescription couldn\'t be updated.')
      }
    })
  }

  const archivePrescription = async(prescription, onComplete) => {
    const updatePrescription = (prescription) => {
      showSuccessAlert(`Prescription has been saved as inactive.`)
      onComplete(prescription)
    }

    if(prescription.id) {
      await archiveExistingPrescription(prescription, updatePrescription)
    } else {
      await createHistoricalPrescription(prescription, updatePrescription)
    }
  }

  const unfavouritePrescription = (prescriptionId, onComplete) => {
    unfavouritePrescriptionGQL({
      variables: {
        tenantId: tenant_id,
        prescriptionId: prescriptionId
      },
      onCompleted: (data) => {
        onComplete()
        showSuccessAlert(`Prescription has been removed from favourites.`)
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert('Prescription couldn\'t be removed from favourites.')
      }
    })
  }

  const incrementFavouritePrescription = (prescriptionId) => {
    incrementFavouritePrescriptionGQL({
      variables: {
        tenantId: tenant_id,
        prescriptionId: prescriptionId
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert('Favourite prescription usage was not incremented.')
      }
    })
  }

  const providerValues = {
    createDraftPrescription,
    updateDraftPrescription,
    deleteDraftPrescription,
    activatePrescription,
    activateSelectedPrescriptions,
    archivePrescription,
    inactivatePrescription,
    renewPrescription,
    renewAllActiveAndContinuousPrescriptions,
    renewSelectedPrescriptions,
    unfavouritePrescription,
    incrementFavouritePrescription,
    prescriptionsQueryRef,
    getPrescriptionQueryResults,
    parsePrescriptionQueryResults
  }

  return (
    <PrescriptionContext.Provider value={providerValues}>
      { children }
    </PrescriptionContext.Provider>
  )
}

export const usePrescriptionContext = () => {
  return React.useContext(PrescriptionContext)
}