import { TaskType } from './TaskType'
import { TaskState } from './TaskState'
import { TaskPriority } from './TaskPriority'
import { TaskTemplate } from './TaskTemplate'
import { AssignedToOptionType } from '../../components/AssignedTo/AssignedTo'
import { PatientSearchResult } from '../patients'
import { Role, User } from '../account'

type TaskAssignmentBase = {
  name?: string
  description?: string
  priority?: TaskPriority
  startDate: Date
  dueDate: Date
  assignedUserId?: string | null
  assignedRoleId?: string | null
  practitionerId?: string
  version: string
  comments?: CommentType[]
}

export type TaskAssignmentInput = TaskAssignmentBase & {
  id?: string
  template?: TaskTemplate | null
  typeId?: string
  type?: TaskType
  stateId?: string
  state?: TaskState
  assignedTo?: AssignedToOptionType
  patientId?: string | null
  patient?: PatientSearchResult
  linkedItems?: LinkedItemType[]
  comment?: string | null
}

export type CommentType = {
  id?: string
  state: CommentStateType

}
export enum CommentStateType{
  UPDATED = 'UPDATED',
  DELETED = 'DELETED',
}

export type LinkedItemType = {

}

export type TaskAssignmentType = TaskAssignmentBase & {
  id: string
  type: TaskType
  state: TaskState
  patient?: PatientSearchResult
  assignedUser?: User
  assignedRole?: Role
}