import { FileType } from "./tasks";
import { Audit } from './Audit'
import { LinkedDocumentReview } from './inbox/ReviewDocument'

export enum DocumentCategory {
  CHART_NOTE = "CHART_NOTE",
  FORM = "FORM",
  INVESTIGATION = "INVESTIGATION",
  LAB_RESULT = "LAB_RESULT",
  LETTER = "LETTER",
  OTHER_DOCUMENT = "OTHER_DOCUMENT",
}

export enum PagesToInclude {
  ALL= "All",
  CURRENT = "Current",
  RANGE = "Range"
}

export enum ReviewRequired {
  YES = "Yes",
  NO = "No"
}

export enum PrintType {
  DOCUMENT = "Document",
  DOCUMENT_AND_MARKUPS = "Documents and markups",
  DOCUMENT_AND_STAMPS = "Documents and stamps",
  FORM_FIELDS_ONLY = "Form fields only",
}

export interface LinkDocumentInput {
  fileId: string
  patientId: string
  category: DocumentCategory | null
  description: string
  documentDate: string
  version: string
}

export interface UpdateLinkedDocumentInput {
  fileId: string
  patientId: string | null
  category: DocumentCategory | null
  description: string
  documentDate: string
  reviewUserId?: string | null
  reviewId?: string | null
  version: string
}

export interface LinkDocumentDetails extends LinkDocumentInput {
  pagesToInclude?: PagesToInclude
  pageRange?: number[][]
  reviewRequired?: ReviewRequired
  assignedUserId?: string
}

export interface LinkedDocumentType {
  id: string,
  fileId: string,
  category: DocumentCategory,
  documentDate: moment.Moment,
  description?: string,
  file: FileType,
  reviews?: LinkedDocumentReview[],
  audit?: Audit,
  version: string
}


export const GetDocumentCategory = (category: DocumentCategory): string => {
  switch(category) {
    case DocumentCategory.CHART_NOTE:
      return "Chart Note"
    case DocumentCategory.FORM:
      return "Form"
    case DocumentCategory.INVESTIGATION:
      return "Investigation"
    case DocumentCategory.LAB_RESULT:
      return "Lab Result"
    case DocumentCategory.LETTER:
      return "Letter"
    case DocumentCategory.OTHER_DOCUMENT:
      return "Other Document"
    default:
      return ""
  }
}