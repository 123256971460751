import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import _get from 'lodash/get'
import {
  LIST_SCHEDULES,
  GET_SCHEDULES,
  LIST_APPOINTMENT_STATES,
  ASSIGN_APPOINTMENT_TO_STATE,
  ASSIGN_APPOINTMENT_TO_ROOM
} from '../graphql-definitions'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import { AppointmentRoom, AppointmentState } from '../types/schedule'

export interface AgendaContextInterface {
  loading?: boolean
  schedules: any[],
  rooms: AppointmentRoom[]
  appointmentStates: AppointmentState[],
  scheduleIds: string[],
  setScheduleIds: (scheduleIds: string[]) => void,
  onChangeState: (id: string, state: AppointmentState, version: string) => Promise<void>,
  onChangeRoom: (id: string, room: AppointmentRoom, version: string) => Promise<void>
}

const defaultAgendaContext: AgendaContextInterface = {
  loading: false,
  schedules: [],
  rooms: [],
  appointmentStates: [],
  scheduleIds: [],
  setScheduleIds: (scheduleIds: string[]) => {},
  onChangeState: (id: string, state: AppointmentState, version: string) => Promise.resolve(),
  onChangeRoom: (id: string, room: AppointmentRoom, version: string) => Promise.resolve()
}

const AgendaContext = React.createContext(defaultAgendaContext)
export const AgendaContextProvider = ({ children }) => {
  const { tenant_id } = useParams()
  const { showErrorAlert, showSuccessAlert } = useAlerts()

  const [scheduleIds, setScheduleIds] = useState<string[]>([])

  const { loading: loadingListSchedules, data: listSchedulesData } = useQuery(LIST_SCHEDULES, {
    variables: { tenantId: tenant_id },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
    },
    fetchPolicy: 'cache-and-network'
  })

  const { loading: loadingSchedules, data: schedulesData } = useQuery(GET_SCHEDULES, {
    variables: {
      tenantId: tenant_id,
      scheduleIdList: scheduleIds,
    },
    fetchPolicy: 'cache-and-network',
    skip: scheduleIds.length < 1
  })

  const { data: appointmentStatesData } = useQuery(LIST_APPOINTMENT_STATES, {
    fetchPolicy: 'cache-first',
    variables: { tenantId: tenant_id },
    onError: (error) => {
      showErrorAlert('Unable to retrieve appointment states.')
      console.error(JSON.stringify(error, null, 2))
    }
  })

  const [assignAppointmentToState] = useMutation(ASSIGN_APPOINTMENT_TO_STATE, {
    onCompleted: () => {
      showSuccessAlert('Appointment has been updated.')
    },
    onError: (error) => {
      showErrorAlert('Appointment couldn\'t be updated.')
      console.error(JSON.stringify(error, null, 2))
    }
  })

  const [assignAppointmentToRoom] = useMutation(ASSIGN_APPOINTMENT_TO_ROOM, {
    onCompleted: () => {
      showSuccessAlert('Appointment has been updated.')
    },
    onError: (error) => {
      showErrorAlert('Appointment couldn\'t be updated.')
      console.error(JSON.stringify(error, null, 2))
    }
  })

  const onChangeState = async (id: string, state: AppointmentState, version: string) => {
    const rooms = _get(schedulesData, 'tenant.schedule.room.rooms', [])
    const currentRoom = rooms.find((r) => r.appointment?.id === id)
    await assignAppointmentToState({
      variables: {
        tenantId: tenant_id,
        appointmentId: id,
        stateId: state.id,
        version: version
      },
      update: (cache, { data }) => {
        cache.modify({
          id: cache.identify({
            id: id,
            __typename: 'Appointment'
          }),
          fields: {
            state() {
              return data.state
            },
            inProgressPit() {
              return data.inProgressPit
            }
          }
        })
        if (currentRoom) {
          cache.modify({
            id: cache.identify({
              id: currentRoom.id,
              __typename: 'AppointmentRoom'
            }),
            fields: {
              appointment() {
                return null
              },
              version() {
                return data.version
          }
            }
          })
        }
      }
    })
  }

  const onChangeRoom = async (id: string, room: AppointmentRoom, version: string) => {
    const rooms = _get(schedulesData, 'tenant.schedule.room.rooms', [])
    await assignAppointmentToRoom({
      variables: {
        tenantId: tenant_id,
        roomId: room.id,
        roomVersion: room.version,
        appointmentId: id,
        appointmentVersion: version,
        previousRoomVersion: rooms.find(r => r.appointment?.id === id)?.version
      },
      update: (cache, { data }) => {
        cache.modify({
          id: cache.identify({
            id: id,
            __typename: 'Appointment'
          }),
          fields: {
            state() {
              return data.state
            },
            inProgressPit() {
              return data.inProgressPit
            }
          }
        })
      }
    })
  }

  let providerValues = {
    loading: loadingListSchedules || loadingSchedules,
    schedules: _get(listSchedulesData, 'tenant.schedule.listSchedules', []),
    rooms: _get(schedulesData, 'tenant.schedule.room.rooms', []),
    appointmentStates: _get(appointmentStatesData, 'tenant.schedule.state.listStates', []),
    scheduleIds,
    setScheduleIds,
    onChangeState,
    onChangeRoom
  }

  return (
    <AgendaContext.Provider value={providerValues}>
      {children}
    </AgendaContext.Provider>
  )
}

export const useAgendaContext = () => {
  return React.useContext(AgendaContext)
}
