import React from 'react'
import { Box, MenuItem } from '@mui/material'
import {
  ConditionAndProcedureIcon,
  AllergiesIcon,
  LabAndInvestigationsIcon,
  FormIcon, LetterIcon, PrescriptionIcon,
  OtherDocumentIcon,
  ChartNotesIcon
} from '../../../patients/components/ChartIcons'
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined'
import NestedDropdown from './NestedDropdown'
import { Controller, useFormContext } from 'react-hook-form'
import { useWatch } from 'saga-library/src/components/Form'
import { ConditionAndProcedureItem, ConditionAndProcedureList } from './components/ConditionAndProcedures'
import { AllergyItem, AllergyList } from './components/Allergies'
import { LabItem, LabList } from './components/Labs'
import { EncounterNoteItem, EncounterNoteList } from './components/EncounterNotes'
import { FormItem, FormList } from './components/Forms'
import { LetterItem, LetterList } from './components/Letter'
import { PrescriptionItem, PrescriptionList } from './components/Prescriptions'
import { OtherDocumentItem, OtherDocumentsList } from './components/OtherDocuments'
import { TaskItem, TaskList } from './components/Tasks'

const LinkItemSelect = () => {
  const controlName = "linkedItems"

  const { control, setValue } = useFormContext()
  const patient = useWatch({
    control,
    name: 'patient'
  })

  if(!patient) {
    setValue(controlName, [])
    return null
  }

  return (
    <Controller
      name={controlName}
      control={control}
      render={ ({field: {value = [], onChange}})=> {
        const onSelect = (v) => {
          const updateValue = [...value, v]
          onChange(updateValue)
        }
        const linkItemTypes = [
          {
            label: 'Conditions and procedures',
            icon: <ConditionAndProcedureIcon />,
            items: [<ConditionAndProcedureList patientId={patient.id} onSelect={onSelect} />]
          },
          {
            label: 'Allergies',
            icon: <AllergiesIcon />,
            items: [<AllergyList patientId={patient.id} onSelect={onSelect} /> ]
          },
          {
            label: 'Labs and investigations',
            icon: <LabAndInvestigationsIcon />,
            items: [<LabList patientId={patient.id} onSelect={onSelect} />]
          },
          {
            label: 'Encounter notes',
            icon: <ChartNotesIcon />,
            items: [<EncounterNoteList patientId={patient.id} onSelect={onSelect} />]
          },
          {
            label: 'Forms',
            icon: <FormIcon />,
            items: [<FormList patientId={patient.id} onSelect={onSelect} />]
          },
          {
            label: 'Letters',
            icon: <LetterIcon/>,
            items: [<LetterList patientId={patient.id} onSelect={onSelect} />]
          },
          {
            label: 'Prescriptions',
            icon: <PrescriptionIcon />,
            items: [<PrescriptionList patientId={patient.id} onSelect={onSelect} />]
          },
          {
            label: 'Other documents',
            icon: <OtherDocumentIcon />,
            items: [<OtherDocumentsList patientId={patient.id} onSelect={onSelect} />]
          },
          {
            label: 'Task',
            icon: <AssignmentTurnedInOutlinedIcon />,
            items: [<TaskList patientId={patient.id} onSelect={onSelect} />]
          }
        ]
        const listStyles = {
          pl:0
        }

        const getItemTypeComponent = (item) => {
          switch(item.__typename){
            case("ConditionAndProcedure"):
              return <ConditionAndProcedureItem
                item={item}
                icon={<ConditionAndProcedureIcon />}
                sx={listStyles}
              />
            case("Allergy"):
              return <AllergyItem
                item={item}
                icon={<AllergiesIcon />}
                sx={listStyles}
              />
            case("AbLabResult"):
              return <LabItem
                item={item}
                icon={<LabAndInvestigationsIcon />}
                sx={listStyles}
              />
            case("EncounterNote"):
              return <EncounterNoteItem
                item={item}
                icon={<ChartNotesIcon />}
                sx={listStyles}
              />
            case("Form"):
              return <FormItem
                item={item}
                icon={<FormIcon/>}
                sx={listStyles}
              />
            case("Letter"):
              return <LetterItem
                item={item}
                icon={<LetterIcon/>}
                sx={listStyles}
              />
            case("Prescription"):
              return <PrescriptionItem
                item={item}
                icon={<PrescriptionIcon />}
                sx={listStyles}
              />
            case("LinkedDocument"):
              return <OtherDocumentItem
                item={item}
                icon={<OtherDocumentIcon />}
                sx={listStyles}
              />
            case("TaskAssignment"):
              return <TaskItem
                item={item}
                icon={<AssignmentTurnedInOutlinedIcon />}
                sx={listStyles}
              />
            default:
              return <MenuItem> Unknown linked item </MenuItem>
          }
        }

        return(
          <>
            <NestedDropdown
              menu={linkItemTypes}
              onSelect={onSelect}
            />
            {value.length > 0 &&
              <Box sx={{mt: 1, display: "flex", flexDirection: "column", gap: 1}}>
                {value.map( item => getItemTypeComponent(item))}
              </Box>
            }
          </>
      )}}
  />
  )
}

export default LinkItemSelect


