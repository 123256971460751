import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { TextField, Refreshable } from "saga-library/src";
import { rules } from '../util/baseClaimValidation'
import { useFormContext, useWatch } from "saga-library/src/components/Form";
import { serviceCodeHospitalAdmission } from '../../../utils/ABClaimConstants'
import { ServiceFeeModifierSelect } from '../../../components/ServiceFeeModifierSelect'
import { ControlledFunctionalCenterInput } from '../../../components/FunctionalCenterSearch/FunctionalCenterInput'
import { LocationCodeSelect } from "../../../components/LocationCodeSelect";
import { ClaimAmount } from "../../../components/ClaimAmount";
import { DiagnosticCodesInfoTooltip, FeeModifierInfoTooltip, ServiceCodeInfoTooltip } from "./ClaimInfoTooltips";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { practitionerDisplayName } from "saga-library/src/util/formatting";
import FormattedDatePicker from "../../../components/FormattedDatePicker";
import { ControlledBillingProfileSelect } from "../../../components/BillingProfileSelect/BillingProfileSelect";
import { FormFragment } from "../../../components/PermissionForm";
import { PermissionType } from "../../../types/settings/Permission";
import { ServiceCodeSearch } from "../../../components/SearchControls/Claims/ServiceCodeSearch";
import { DiagnosticCodeSearch } from "../../../components/SearchControls/Claims/DiagnosticCodeSearch";
import { FacilitySearch } from "../../../components/SearchControls/Claims/FacilitySearch";
import { ReferralPractitionerSearch } from "../../../components/SearchControls/Practitioner/ReferralPractitionerSearch";
import { PatientLookup } from "../../../components/PatientLookup/PatientLookup";
import PatientRefreshable from "../../../components/SearchControls/Patient/PatientRefreshable";

const Form = ({ isAssessed }) => {

  const { control, resetField, getValues, setValue, setFocus } = useFormContext()

  const [practitionerId, setPractitionerId] = useState(
    getValues('practitionerId')
  )
  const { showSuccessAlert } = useAlerts()

  const serviceDate = useWatch({
    control,
    name: 'serviceDate',
  })

  const serviceCode = useWatch({
    control,
    name: 'serviceCode',
  })

  const diagnosticCodes = useWatch({
    control,
    name: 'diagnosticCodes',
  })

  const feeModifiers = useWatch({
    control,
    name: 'serviceCodeFeeModifiers',
  })

  const patient = useWatch({
    control,
    name: 'patient'
  })

  useEffect(() => {
    if (patient) {
      if (!serviceCode && patient.serviceCode) {
        setValue('serviceCode', patient?.serviceCode)
      }
      if(!diagnosticCodes.length && patient.diagnosticCodes?.length) {
        const diagnosticCodeArray = patient.diagnosticCodes?.filter((element) => {
          return element !== null
        })
        setValue('diagnosticCodes', diagnosticCodeArray)
      }
    }
  }, [patient])

  const handleBillingProfileChange = (billingProfile) => {
    if (billingProfile) {
      setPractitionerId(billingProfile.practitionerId)
      setValue('functionalCenter', billingProfile.functionalCenter)
      setValue('facility', billingProfile.facility)
      setValue('skillId', billingProfile.skill?.id)
    }
    else {
      setPractitionerId('')
      setValue('functionalCenter', null)
      setValue('facility', null)
      setValue('skillId', '')
    }
  }

  return (
    <FormFragment requiredPermissionType={PermissionType.Billing}>
      <Box
        display={'flex'}
        flexDirection={'row'}
        gap={2}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          gap={'4px'}
          width={'100%'}
        >
          {isAssessed?
            <Refreshable
              label={'Practitioner'}
              name={'updatePractitioner'}
              textValue={getValues('billingProfile.name') + ' (' + practitionerDisplayName(getValues('billingProfile.practitionerFirstName'), getValues('billingProfile.practitionerLastName')) + ')'}
              onRefreshDisplaySnackbar={(value) => {
                if (!value) {
                  showSuccessAlert('Practitioner information will be updated when this claim is resubmitted.')
                }
                else {
                  showSuccessAlert('Practitioner information will not be updated when this claim is resubmitted.')
                }
              }}
            />
            :
            <ControlledBillingProfileSelect
              label={'Practitioner'}
              name={'billingProfile'}
              onSelect={(billingProfile) => {
                handleBillingProfileChange(billingProfile)
                setFocus("patient")
              }}
              autoFocus={true}
            />
          }
          {
            isAssessed && patient ?
              <PatientRefreshable
                name={'updatePatient'}
                patient={patient}
                onRefreshDisplaySnackbar={(value) => {
                  if (!value) {
                    showSuccessAlert('Patient information will be updated when this claim is resubmitted.')
                  }
                  else {
                    showSuccessAlert('Patient information will not be updated when this claim is resubmitted.')
                  }
                }}
              />
              :
              <PatientLookup
                name={"patient"}
                label={"Patient"}
                includeInactive={true}
                patientLabelSx={{ mb: 2 }}
              />
          }
          <FormattedDatePicker
            label={'Service date'}
            name={'serviceDate'}
            sx={{ mt: '4px' }}
          />
          <Box
            sx={{
              border: 1,
              borderRadius: '8px',
              borderColor: 'greys.light',
              p: 1
            }}
          >
            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              gap={'4px'}
              sx={{ color: 'greys.light' }}
            >
              <ServiceCodeSearch
                label={'Service code'}
                name={'serviceCode'}
                fullWidth={true}
                serviceDate={serviceDate}
                practitionerId={practitionerId}
              />
              <ServiceCodeInfoTooltip serviceCode={serviceCode}/>
            </Box>
            {serviceCode &&
              serviceCodeHospitalAdmission.includes(
                serviceCode['serviceCode']
              ) && (
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignContent={'center'}
                  justifyContent={'space-evenly'}
                >
                  <FormattedDatePicker
                    label={'Hospital admission date'}
                    name={'hospitalAdmissionDate'}
                    sx={{ width: '100%' }}
                  />
                </Box>
              )}
            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              alignContent={'center'}
              gap={'4px'}
              sx={{ color: 'greys.light' }}
            >
              <DiagnosticCodeSearch
                label={'Diagnostic codes'}
                name={'diagnosticCodes'}
                fullWidth={true}
                sx={{ my: 1 }}
                practitionerId={practitionerId}
                serviceDate={serviceDate}
              />
              <DiagnosticCodesInfoTooltip diagnosticCodes={diagnosticCodes}/>
            </Box>
            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              gap={'4px'}
              sx={{ color: 'greys.light' }}
            >
              <ServiceFeeModifierSelect
                serviceCodeId={serviceCode?.id || ''}
                sx={{ my: 1, width: '100%' }}
                inputProps={{ maxLength: rules.feeModifiers.max }}
              />
              <FeeModifierInfoTooltip feeModifiers={feeModifiers} />
            </Box>
            <Box
              display={'flex'}
              flexDirection={'row'}
              gap={2}
            >
              <TextField
                label={'Calls'}
                name={'calls'}
                type={'number'}
                inputProps={{ maxLength: rules.calls.max }}
                sx={{ width: '112px' }}
              />
              <TextField
                label={'Encounter'}
                name={'encounter'}
                type={'number'}
                inputProps={{ maxLength: rules.encounter.max }}
                sx={{ width: '112px' }}
              />
            </Box>
            <ClaimAmount />
          </Box>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          gap={'4px'}
          width={'100%'}
        >
          <FacilitySearch
            name={'facility'}
            label={'Facility'}
            practitionerId={practitionerId}
            serviceDate={serviceDate}
          />
          <ControlledFunctionalCenterInput
            name={'functionalCenter'}
            onClear={() => resetField('functionalCenter')}
          />
          <LocationCodeSelect
            label={'Location'}
            name={'locationCode'}
            sx={{ mb: 0 }}
          />
          <ReferralPractitionerSearch
            label={'Referral practitioner'}
            name={'referralPractitioner'}
            dataTestId={'claimBaseDetail-referral-search'}
          />
          <TextField
            label={'Text'}
            name={'text'}
            inputProps={{ maxLength: rules.text.max, style: { 'height': '100%', 'overflow': 'auto'  } }}
            multiline={true}
            hideHelperText={true}
            InputProps={{ sx: { mt: 1 }, style: { minHeight: '40px', flexGrow: 1, flexShrink: 1 } }}
            InputLabelProps={{ sx: { mt: 1 } }}
            sx={{ height: '100%', mt: 0, overflow: 'hidden' }}
            dataTestId={'claimBaseDetail-text'}
          />
        </Box>
      </Box>
    </FormFragment>
  )
}

export const ClaimBaseDetails = {
  Form: Form,
}
