import { TextField } from 'saga-library/src'
import { Controller, useFormContext } from 'react-hook-form'
import { List } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { CommentListItem } from './CommentListItem'


export const CommentsField = ({dataTestId, onSave=()=>null}) => {
  return (
    <>
      <TextField
        name={'comment'}
        label={'Comment'}
        multiline={true}
        minRows={3}
        fullWidth={true}
        dataTestId={`${dataTestId}-comment`}
      />

      <CommentsList dataTestId={dataTestId} onSave={onSave} />
    </>
  )
}

const CommentsList = ({dataTestId, onSave}) => {
  const { control, formState } = useFormContext()
  const [ editComments, setEditComments] = useState<number[]>([])

  useEffect(() => {
    if (formState.isSubmitted && editComments.length > 0) {
      setEditComments([])
    }
  }, [formState]);

  return <Controller
    name={"comments"}
    control={control}
    render={({field: {value = [] }}) => {
      return (
        <List> {
          value.map((c, i) => <CommentListItem
            key={`comment_${c.id}`}
            taskComment={c}
            dataTestId={dataTestId}
            index={i}
            onEdit={()=>setEditComments([...editComments, i])}
            onCancel={()=>setEditComments(editComments.filter(c => c!==i))}
            editComment={editComments.includes(i)}
          />) }
        </List>
      )
    }}
  />
}