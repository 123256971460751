import {
  IconImageTooltip,
  TableList,
  TableListCellConfig,
  TableListHeaderConfig,
  TableListRowConfig,
  Typography
} from 'saga-library/src'
import React, { useMemo } from 'react'
import Widget from '../Widget'
import { SxProps } from '@mui/material'
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined'
import { useReadQuery } from '@apollo/client'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import { TaskAssignmentType } from '../../../../types/tasks/TaskAssignment'
import { TaskPriorityMap } from '../../../../types/tasks/TaskPriority'
import { useFormattedDate } from '../../../../hooks/FormattedDate'
import moment from 'moment-timezone'
import { TaskCategory } from '../../../../types/tasks/TaskState'
import { usePatientTaskContext } from '../../providers/PatientTaskProvider'

const columns: TableListHeaderConfig[] = [
  { name: '' },
  { name: '' },
  { name: '' },
  { name: '' }
]

export const TaskWidget = () => {
  const { showWarningAlert } = useAlerts()

  return (
    <Widget
      title={'Tasks'}
      route={'tasks'}
      WidgetIcon={AssignmentTurnedInOutlinedIcon}
      onAddItem={() => showWarningAlert('Not yet implemented')}
    >
      <TaskWidgetBody onRowClick={() => showWarningAlert('Not yet implemented')} />
    </Widget>
  )
}


const TaskWidgetBody = ({ onRowClick }) => {
  const { parsePatientTaskQueryResults, patientTasksRef } = usePatientTaskContext()
  const { data } = useReadQuery(patientTasksRef)
  const tasks = useMemo(() => parsePatientTaskQueryResults(data), [data, parsePatientTaskQueryResults])

  return (
    <TableList
      dataTestId={'task-widget-table'}
      columns={columns}
      showHeader={false}
      rows={[
        ...tasks.map((task) => TaskRow(task, onRowClick))
      ]}
      sx={{ height: '100%', "&.MuiTableCell-root": { borderBottom: null } }}
      tableSx={{ tableLayout: 'fixed' }}
      emptyListComponent={{ message: "Click the add button to add a task.", size: 'sm', sx: { paddingTop: '1vh', textAlign: 'left' } }}
      isWidget={true}
    />
  )
}


function TaskRow(task: TaskAssignmentType, onRowClick) : TableListRowConfig {
  const rowData = [
    {
      children: <TaskDate {...task} />,
      sx: { boxSizing: 'border-box', width: '96px' }
    },
    {
      children: task.priority && (
        <IconImageTooltip title={TaskPriorityMap.get(task.priority)!.label}>
          {TaskPriorityMap.get(task.priority)!.icon}
        </IconImageTooltip>
      ),
      sx: { px: 0, width: '16px' }
    },
    {
      children: <Typography lineclamp={1} variant={'p2'}>{task.type.type}</Typography>,
      sx: { boxSizing: 'border-box', width: '96px' }
    },
    {
      children: <Typography lineclamp={1} variant={'p2'}>{task.name}</Typography>
    }
  ] as TableListCellConfig[]

  return {
    onClick: () => onRowClick(task),
    rowData,
    key: task.id
  }
}

export const TaskDate = ({ dueDate, state }: TaskAssignmentType) => {
  const taskDate = useFormattedDate(dueDate)

  const isOverdue = dueDate && moment(dueDate).isBefore(moment(), 'day')
  const isIncomplete = state.category === TaskCategory.INCOMPLETE
  let sx: SxProps | undefined
  if (isOverdue && isIncomplete) {
    sx = { color: 'error.main', backgroundColor: 'backgrounds.error' }
  }

  return <Typography variant={'p2'} sx={sx}>{taskDate}</Typography>
}

