import { useParams } from 'react-router-dom'
import { useSuspenseQuery } from '@apollo/client'
import { GET_PATIENT_LAB_AND_INVESTIGATIONS } from '../../../../../graphql-definitions'
import { parseLabAndInvestigationQueryResults } from '../../../../patients/providers/LabAndInvestigationProvider'
import { EmptyList, Typography } from 'saga-library/src'
import { AbLabResult } from '../../../../../types/patients'
import { Box, useTheme } from '@mui/material'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import React from 'react'
import { ItemType, ListBox, ListMenuItem, ListMenuItemDate } from '../LinkItem'

export const LabList = ({patientId, onSelect}) => {
  const {tenant_id} = useParams()
  const {data} = useSuspenseQuery(GET_PATIENT_LAB_AND_INVESTIGATIONS ,{
    variables:{
      patientId: patientId,
      tenantId: tenant_id
    }
  })

  const labs = parseLabAndInvestigationQueryResults(data)

  if(labs.length > 0) {
    return (
      <ListBox>
        {labs.map(l => <LabItem onClick={onSelect} item={l}  key={`link_patient_lab_${l.id}`} />)}
      </ListBox>
    )
  }

  return (
    <EmptyList sx={{p:1, width:"200px"}} message={ `No associated labs`} />
  )
}

export const LabItem = ({ item, onClick, icon, ...itemTypeProps  }:ItemType) => {
  const lab = item as AbLabResult
  const theme = useTheme()
  const categoryLabel = lab.isLab ? "Lab result" : "Investigation"
  const observationResultCount = lab.abLabResultObservationResults?.length
  const firstObservationResult = lab.abLabResultObservationResults?.[0]
  return (
    <ListMenuItem onClick={onClick ? (e)=>onClick(e, lab)  : undefined} icon={icon} {...itemTypeProps}>
      <Box flex={'0 0 85px'}>
        <ListMenuItemDate date={lab.date} variant={"body1"} color={lab.isAbnormal ? theme.palette.error.main  : "inherit"}/>
      </Box>
      <Box flex={'0 0 150px'}>
        <Typography
          lineclamp={1}
          color={(theme) => lab.isAbnormal ? theme.palette.error.main : "inherit"}
          sx={{ wordBreak: "break-all" }}
        >
          {lab.description ? lab.description : categoryLabel}
        </Typography>
      </Box>
      <Box display={"inline-flex"} sx={{ verticalAlign: "middle", flex:"0 0 24px"}}>
        {lab.isLab && lab.isAbnormal &&
          <WarningAmberIcon color={"error"} />
        }
      </Box>
      <Box color={(theme) => lab.isAbnormal ? theme.palette.error.main : "inherit"} flex={'1 1 100%'}>
        {lab.isLab && (
          <>
            {observationResultCount > 1
              ? <Typography lineclamp={1} fontStyle={"italic"} sx={{ wordBreak: "break-all" }}>Multiple values</Typography>
              : <Typography lineclamp={1} sx={{ wordBreak: "break-all" }}>
                {firstObservationResult?.observationValue
                  ? firstObservationResult?.observationValue + (firstObservationResult?.unitsDescription ? " " + firstObservationResult?.unitsDescription : "")
                  : ""
                }
              </Typography>
            }
          </>
        )}
      </Box>
    </ListMenuItem>
  )
}
