import React, {lazy} from 'react'
import { useParams } from 'react-router-dom'
import { useConfigurationContext } from '../../providers/Configuration'
import { useDocumentContext } from '../../providers/DocumentProvider'
import { ControlledRichTextEditorProps } from 'saga-library/src/components/RichTextEditor/RichTextEditor'
import { GET_FILE_DETAILS_FROM_URL } from '../../graphql-definitions'
import { DocumentNode } from 'graphql/language'
import { useEditorImageContext } from '../../providers/EditorImageContextProvider'
import { ComponentSuspenseWrapper } from '../ComponentSuspenseWrapper'

const ControlledRichTextEditor =lazy(() => import('saga-library/src/components/RichTextEditor/RichTextEditor'))

interface RichTextEditorClientProps extends Omit<ControlledRichTextEditorProps,
  'froalaKey' | 'uploadFiles' | 'deleteFile' |
  'getFileTypeUriWithToken' | 'tenantId' | 'getFileDetailsCall' |
  'setImageUploading' | 'addImageFileToEditor' | 'removeImageFileFromEditor' > {
  getFileDetailsCall?: DocumentNode
}

export const RichTextEditorClient = ({
  getFileDetailsCall = GET_FILE_DETAILS_FROM_URL,
  ...props
}: RichTextEditorClientProps) => {
  const { getConfigValue } = useConfigurationContext()
  const { tenant_id } = useParams()
  const froalaKey = getConfigValue('FROALA_CLIENT_TOKEN')
  const { uploadFiles, getFileTypeUriWithToken } = useDocumentContext()
  const { setImageUploading, addImageToEditor, removeImageFromEditor } = useEditorImageContext()

  return (
    <ComponentSuspenseWrapper>
      <ControlledRichTextEditor
        froalaKey={froalaKey}
        tenantId={tenant_id}
        uploadFiles={uploadFiles}
        getFileTypeUriWithToken={getFileTypeUriWithToken}
        getFileDetailsCall={getFileDetailsCall}
        setImageUploading={setImageUploading}
        addImageFileToEditor={addImageToEditor}
        removeImageFileFromEditor={removeImageFromEditor}
        {...props}
    />
    </ComponentSuspenseWrapper>
  )
}