import React from 'react'
import { getLabAndInvestigationLabel } from '../LabAndInvestigationsUtil'
import { AbLabResult, AbLabResultPatientIdentifier } from '../../../../../types/patients'
import { Chip, Typography } from 'saga-library/src'
import { Box, SxProps, useTheme } from '@mui/material'
import { useFormattedDate } from '../../../../../hooks/FormattedDate'
import { patientDisplayName } from 'saga-library/src/util'
import _uniqWith from 'lodash/uniqWith'
import { Theme } from '@mui/system'

export interface LabAndInvestigationLabelProps {
  labAndInvestigation: AbLabResult,
  dataTestId?: string,
  sx?: SxProps<Theme>
}

export const LabAndInvestigationLabel = ({labAndInvestigation}:LabAndInvestigationLabelProps) => {
  const theme = useTheme()
  const collectedOn = useFormattedDate(labAndInvestigation.abLabResultSpecimen?.collectionDate, true)

  const resultWithEmbeddedFile = labAndInvestigation?.abLabResultObservationResults?.find(r => r.file)
  const label = getLabAndInvestigationLabel(labAndInvestigation)

  const chipLabel = labAndInvestigation.abLabResultObservationRequest?.resultStatus?.display
    ?? labAndInvestigation.abLabResultTranscription?.documentAvailabilityStatus?.display
    ?? labAndInvestigation.abLabResultTranscription?.documentCompletionStatus?.display

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
    >
      <Typography variant={"inherit"}>{label}</Typography>
      {!resultWithEmbeddedFile &&
        <Box
          display={"flex"}
          flexDirection={"row"}
          gap={1}
          height={"20px"}
        >
          {chipLabel?.toLowerCase() === "cancel" || chipLabel?.toLowerCase() === "cancelled" || chipLabel?.toLowerCase() === "deleted"
            ? <Chip
                data-testid={"lab-and-investigation-label-chip"}
                label={chipLabel}
                size={"small"}
                variant={"outlined"}
                sx={{
                  color: theme.palette.error.main,
                  borderColor: theme.palette.error.main
                }}
              />
            : <Chip
                data-testid={"lab-and-investigation-label-chip"}
                label={chipLabel}
                size={"small"}
              />
          }
          <Box
            display={"flex"}
            flexDirection={"row"}
            gap={2}
          >
            <LabAndInvestigationLabelField label={"Collected on:"} value={collectedOn}/>
          </Box>
        </Box>
      }
    </Box>
  )
}

export const LabAndInvestigationPatientLabel = ({
  labAndInvestigation,
  dataTestId,
  sx,
}: LabAndInvestigationLabelProps) => {
  const resultWithEmbeddedFile = labAndInvestigation?.abLabResultObservationResults?.find(r => r.file)
  const dob = useFormattedDate(labAndInvestigation.dob)
  const uniqueIdentifiers : AbLabResultPatientIdentifier[] = _uniqWith(labAndInvestigation.patientIdentifiers, (a, b) => a.label === b.label && a.assigningAuthority?.display === b.assigningAuthority?.display)

  if (resultWithEmbeddedFile) {
    return null
  }

  const patientFields = [
    <Typography variant={"body1"} key={"dob"}>{dob}</Typography>,
    <Typography variant={"body1"} key={"gender"}>{labAndInvestigation?.gender?.display}</Typography>,
    ...(uniqueIdentifiers.map(identifier =>
      <Typography variant={"body1"} key={`identifier-${identifier.id}`}>{identifier.assigningAuthority?.display}: {identifier.label}</Typography>
    ) ?? [])
  ]

  return (
    <Box
      flexDirection={"row"}
      alignItems={"center"}
      gap={1}
      sx={{ ...sx }}
      data-testid={dataTestId}
    >
      <Typography variant={"h3"}>{patientDisplayName(labAndInvestigation?.patientName?.firstName, labAndInvestigation?.patientName?.lastName)}</Typography>
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
      >
        {patientFields.reduce((fields, field) => {
          return (
            <>
              {fields}
              <Typography variant={"body1"}>&nbsp;•&nbsp;</Typography>
              {field}
            </>
          )
        })}
      </Box>
    </Box>
  )
}

interface LabAndInvestigationLabelFieldProps {
  label?: string,
  value?: string
}

const LabAndInvestigationLabelField = ({label, value}:LabAndInvestigationLabelFieldProps) => {
  if (!label || !value) {
    return null
  }

  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
    >
      <Typography variant={"body2"} sx={{color: 'greys.medium'}}>{label}&nbsp;</Typography>
      <Typography variant={"body2"}>{value}</Typography>
    </Box>
  )
}
