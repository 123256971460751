import { useParams } from 'react-router-dom'
import { useSuspenseQuery } from '@apollo/client'
import { GET_PATIENT_ALLERGIES } from '../../../../../graphql-definitions/tenant/patient/AllergyQueries'
import { parseAllergyQueryResults } from '../../../../patients/providers/AllergyProvider'
import { EmptyList, IconImageTooltip, Typography } from 'saga-library/src'
import { AllergySeverityMap, AllergyType, getOnsetType } from '../../../../../types/Allergy'
import { Box } from '@mui/material'
import React from 'react'
import { ItemType, ListBox, ListMenuItem } from '../LinkItem'

export const AllergyList = ({patientId, onSelect}) => {
  const {tenant_id} = useParams()
  const {data} = useSuspenseQuery(GET_PATIENT_ALLERGIES ,{
    variables:{
      patientId: patientId,
      tenantId: tenant_id
    }
  })

  const allergies = parseAllergyQueryResults(data)
  if(allergies.length > 0) {
    return(
      <ListBox>
        {allergies.map(a => <AllergyItem item={a} onClick={onSelect} key={`link_patient_allergy_${a.id}`}/>)}
      </ListBox>
    )
  }

  return (
    <EmptyList sx={{p:1, width:"200px"}} message={ `No associated allergies`} />
  )
}

export const AllergyItem = ({ item, onClick, icon, ...itemTypeProps }:ItemType) => {
  const allergy = item as AllergyType
  return (
    <ListMenuItem
      onClick={onClick ? (e) => onClick(e, allergy) : undefined}
      icon={icon}
      {...itemTypeProps}
    >
      <Box display={'flex'} flexDirection={'column'} flex={'0 0 164px'}>
        <Typography lineclamp={1}>{allergy.allergen}</Typography>
        <Typography lineclamp={1} variant={'p2'}>
          {getOnsetType(allergy)}
        </Typography>
      </Box>
      <Box flex={'0 0 16px'}>
        <IconImageTooltip
          title={AllergySeverityMap.get(allergy.severity!)!.label}
        >
          {AllergySeverityMap.get(allergy.severity!)!.icon}
        </IconImageTooltip>
      </Box>
      <Box flex={'1 1 100%'}>
        <Typography lineclamp={2}>{allergy.reactionDescription}</Typography>
      </Box>
    </ListMenuItem>
  )
}