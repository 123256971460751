import React, { useState, useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import UpdateIcon from '@mui/icons-material/Update'
import ReceiptLong from "@mui/icons-material/ReceiptLongOutlined";
import SelectAllIcon from '@mui/icons-material/SelectAll'
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { ActionComponent } from "../UnsubmittedClaims/UnsubmittedMultipleSelected";
import { styled } from "@mui/system";
import { Typography, IconButton } from "saga-library/src";
import {useMutation, useReadQuery} from "@apollo/client";
import { useNavigate, useParams } from 'react-router-dom'
import { useAlerts } from "saga-library/src/providers/Alerts";
import { ACCEPT_ADJUSTMENTS, WRITEOFF_CLAIMS } from "../../../../graphql-definitions";
import { useAssessedClaimsContext } from '../../providers/AssessedClaimsProvider'
import { WriteOffConfirmationDialog } from './AssessedClaimDialogs'
import _get from "lodash/get";

const BillingsIcon = styled(ReceiptLong)(({ theme }) => ({
  color: theme.palette.greys.light,
  fontSize: '100px',
}))



export const withMultipleSelectedHOC = WrappedComponent => (props) => {
  const { selectedClaimIds } = useAssessedClaimsContext()
  return(
    <>
      <Box display={selectedClaimIds.length === 0 ? 'none' : undefined}>
        <AssessedMultipleSelected/>
      </Box>
      <Box display={selectedClaimIds.length > 0 ? 'none' : undefined}>
        <WrappedComponent {...props} />
      </Box>
    </>
  )
}

export const AssessedMultipleSelected = () => {
  const theme = useTheme()
  const { tenant_id } = useParams()
  const navigate = useNavigate()
  const { showErrorAlert, showSuccessAlert } = useAlerts()

  const [acceptAdjustments, { error: adjustmentsError }] = useMutation(ACCEPT_ADJUSTMENTS)
  const [writeOffClaims, { error: writeOffError }] = useMutation(WRITEOFF_CLAIMS)

  const { assessedClaimsListQueryRef, selectedClaimIds, setSelectedClaimIds } = useAssessedClaimsContext()
  const [openWriteOffDialog, setOpenWriteOffDialog] = useState(false)

  const [claimStatus, setClaimStatus] = useState("")

  const data = useReadQuery(assessedClaimsListQueryRef!)
  const claimsList = _get(data, 'data.tenant.assessedAbClaim.assessedClaims.edges', [] as any).map(e => e.node)


  useEffect(() => {
    const selectedClaims = claimsList.filter(c => selectedClaimIds.includes(c.id))

    if (selectedClaims.length <= 0) {
      return
    }

    const uniqueOutcomeCheck = selectedClaims.every(sc => sc.assessmentOutcome === selectedClaims[0].assessmentOutcome)
    if (!uniqueOutcomeCheck) throw new Error('Unsupported application state: Different assessment outcomes for selected claims.')

    let status = ""
    if (selectedClaims[0].assessmentOutcome === 'APPLIED'){
      status = 'adjusted'
    }
    if (selectedClaims[0].assessmentOutcome === 'REFUSED') {
      status = 'refused'
    }
    if (selectedClaims[0].assessmentOutcome === 'HELD') {
      status = 'held'
    }
    setClaimStatus(status)

  }, [claimsList, selectedClaimIds])


  const handleSelectAll = () => {
    // TODO: What does "select all" mean when the list is paged?
    console.warn("NYI")
  }

  const handleBulkAccept = async() => {
    await acceptAdjustments({
      variables: {
        tenantId: tenant_id,
        ids: selectedClaimIds
      },
      onCompleted: () => {
        showSuccessAlert(`${selectedClaimIds.length} ${selectedClaimIds.length === 1 ? 'claim has' : 'claims have'} been accepted.`)
        setSelectedClaimIds([])
      },
      onError: () => {
        console.error(JSON.stringify(adjustmentsError, null, 2))
        showErrorAlert('Claims couldn\'t be accepted.')
      }
    })
  }

  const handleBulkWriteOff = () => {
    setOpenWriteOffDialog(true)
  }

  const handleWriteOffConfirmed = async(code) => {
    await writeOffClaims({
      variables: {
        tenantId: tenant_id,
        ids: selectedClaimIds,
        code: code
      },
      onCompleted: () => {
        showSuccessAlert(`${selectedClaimIds.length} ${selectedClaimIds.length === 1 ? 'claim has' : 'claims have'} been written off.`)
        setSelectedClaimIds([])
      },
      onError: () => {
        console.error(JSON.stringify(writeOffError, null, 2))
        showErrorAlert('Claims couldn\'t be written off.')
      }
    })
  }

  const handleUpdate = () => {
    navigate(`../c/update-batch`)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mt: 6,
      }}>
      <WriteOffConfirmationDialog
        open={openWriteOffDialog}
        setOpen={setOpenWriteOffDialog}
        onAccept={handleWriteOffConfirmed}

      />
      <BillingsIcon />
      <Typography variant={'h2'}>{selectedClaimIds.length} {selectedClaimIds.length === 1 ? 'claim' : 'claims'} selected</Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          color: theme.palette.primary.main,
          marginTop: '20px',
        }}
      >
        <IconButton onClick={handleSelectAll} icon={<SelectAllIcon/>} />
        <Typography fontWeight={'bold'} sx={{ marginLeft: '8px' }}>Select all {claimStatus} claims</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mt: 4,
          color: theme.palette.primary.main,
        }}
      >
        <ActionComponent
          icon={<UpdateIcon/>}
          text={'UPDATE'}
          onClick={handleUpdate}
        />
        {
          claimStatus === 'refused' &&
          <ActionComponent
            icon={<MoneyOffIcon />}
            text={'WRITE OFF'}
            onClick={handleBulkWriteOff}
          />
        }
        {claimStatus === 'adjusted' &&
          <ActionComponent
            icon={<CheckCircleOutlineIcon/>}
            text={'ACCEPT'}
            onClick={handleBulkAccept}
          />
        }
        <hr
          style={{
            alignItems: 'center',
            color: theme.palette.greys.light,
            width: '120px',
            marginTop: '32px',
          }}
        />

        <ActionComponent
          icon={<ClearOutlinedIcon/>}
          text={'CANCEL'}
          onClick={() => setSelectedClaimIds([])}
        />
      </Box>
    </Box>
  )
}

