import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined'
import { Button, Section, Typography } from 'saga-library/src'
import React, { useState } from 'react'
import { Box, createSvgIcon, Divider } from '@mui/material'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import { ReactComponent as RecentSVG } from '../../../../assets/RecentIcon.svg'
import { UpdateTaskStatusDialog } from './UpdateTaskStatusDialog'

const RecentIcon = createSvgIcon(<RecentSVG />, "Update")

type TaskMultipleSelectedProps = {
  selectedTasks: string[]
  onSuccess: () => void
  onCancel: () => void
}

export const TaskMultipleSelected = ({
  selectedTasks = [],
  onSuccess,
  onCancel
}: TaskMultipleSelectedProps) => {
  const [ openDialog, setOpenDialog ] = useState<boolean>(false)

  return (
    <>
      <Section.Column rightPadding={1} sx={{ flex: "1 1 100%" }}>
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} gap={4} height={"100%"}>
          <Box display={"flex"} flexDirection={"inherit"} alignItems={"inherit"}>
            <AssignmentTurnedInOutlinedIcon sx={{ color: (theme) => theme.palette.greys.medium, fontSize: "88px" }} />
            <Typography variant={"h2"}>{selectedTasks.length} task(s) selected</Typography>
          </Box>
          <Box display={"flex"} flexDirection={"inherit"} gap={2} width={"200px"}>
            <Button
              name={"update-task-status"}
              variant={"text"}
              startIcon={<RecentIcon />}
              onClick={() => setOpenDialog(true)}
            >
              Update status
            </Button>
            <Divider sx={{ borderColor: "rgba(32, 41, 49, 0.32)" }} />
            <Button
              name={"cancel-task-selection"}
              variant={"text"}
              startIcon={<ClearOutlinedIcon />}
              onClick={onCancel}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Section.Column>
      <UpdateTaskStatusDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onSuccess={onSuccess}
        selectedTasks={selectedTasks}
      />
    </>
  )
}