import moment from "moment";

export const addDurationToDate = (duration, date, ignoreDST = false) => {
  const currentDate = moment(date)
  const updatedDate = moment(currentDate).add(moment.duration(duration).asSeconds(), 'seconds')
  if (ignoreDST) return updatedDate
  return adjustDateForDST(currentDate, updatedDate)
}

export const diffDates = (startDate, endDate, units = 'milliseconds', ignoreDST = false) => {
  const date1 = moment(startDate)
  let date2 = moment(endDate)
  if (!ignoreDST) date2 = adjustDateForDST(date1, date2)
  return date2.diff(date1, units as moment.unitOfTime.Diff)
}

const adjustDateForDST = (startDate, endDate) => {
  const date1 = moment(startDate)
  const date2 = moment(endDate)
  if (date1.isDST() && !date2.isDST()) {
    return date2.add(1, 'hours')
  }
  if (!date1.isDST() && date2.isDST()) {
    return date2.subtract(1, 'hours')
  }
  return date2
}

export const getFormattedDuration = (startDate, endDate) => {
  const duration = moment.duration(diffDates(startDate, endDate))
  const minutes = duration.asMinutes()
  if (minutes / 60 >= 1) {
    const hours = duration.asHours()
    let durationString = hours > 1 ? `${hours} hrs` : `${hours} hr`
    if (minutes % 60 !== 0) {
      durationString += ` ${minutes % 60} mins`
    }
    return durationString
  }

  return `${minutes} mins`
}