import React from 'react'
import Menu from '@mui/material/Menu'
import { Box, Typography } from '@mui/material'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import ArrowDropUp from '@mui/icons-material/ArrowDropUp'
import AddLink from '@mui/icons-material/AddLink'
import NestedDropdownItem from './NestedDropdownItem'

interface DropdownProps {
  menu: any[]
  onOpen?: (event: React.MouseEvent<HTMLElement> | null) => void
  getMenuItemComponent?: (item:any)=>React.ReactNode
  onSelect?: (item: any) => void
}

const NestedDropdown = React.forwardRef<HTMLDivElement, DropdownProps>(
  (
    {
      menu,
      onOpen: onControlledOpen,
      getMenuItemComponent,
      onSelect
    },
    ref
  ) => {
    const [isInternalOpen, setInternalOpen] = React.useState<null | HTMLElement>(null)

    const isOpen = isInternalOpen

    let anchorRef: React.RefObject<HTMLDivElement> = React.useRef(null)
    if (ref) {
      anchorRef = ref as React.RefObject<HTMLDivElement>
    }

    const handleOpen = (event) => {
      event.stopPropagation()

      if (menu) {
        onControlledOpen
          ? onControlledOpen(event.currentTarget)
          : setInternalOpen(event.currentTarget)
      }
    }

    const handleClose = (event) => {
      event.stopPropagation()

      if (
        anchorRef &&
        anchorRef.current &&
        anchorRef.current.contains(event.target as Node)
      ) {
        return
      }

      handleForceClose()
    }

    const handleForceClose = () => {
      onControlledOpen
        ? onControlledOpen(null)
        : setInternalOpen(null)
    }

    const onItemClick = (event) => {
      event.stopPropagation()

      handleClose(event)
    }
    const onNestedItemClick = (event, value) => {
      if(onSelect){
        onSelect(value)
      }

      onItemClick(event)
    }

    return (
      <>
        <DropdownButton
          label={"Link item"}
          icon={ <AddLink />}
          onClick={isOpen ? handleForceClose : handleOpen}
          isOpen={!!isOpen}
        />

        <Menu
          PaperProps={{ sx: {width: isInternalOpen ? isInternalOpen.offsetWidth : "120px" }}}
          anchorEl={isOpen}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          open={!!isOpen}
          onClose={handleClose}
        >
          {menu.map(o=> {
            return (
              <NestedDropdownItem
                key={`nestedDropdownItem-${o.label}`}
                label={o.label}
                leftIcon={o.icon}
                parentMenuOpen={!!isOpen}
                onClick={(e)=>onItemClick(e)}
                onNestedItemClick={(e ,v) => onNestedItemClick(e ,v)}
                onOpen={o.onSelect}
                items={o.items}
                getMenuItemComponent={getMenuItemComponent}
              />
            )
          })}
        </Menu>
      </>
    )
  }
)

const DropdownButton = ({label, icon, onClick, isOpen = false}) =>{
  return (
    <Box
      sx={theme => ({
        height: "40px",
        border: isOpen ? `1px solid ${theme.palette.primary.main }` : `1px solid ${theme.palette.greys.ui }`,
        color: 'greys.light',
        borderRadius: "8px",
        "&:hover": {
          color: 'greys.light',
          border: isOpen ? `1px solid ${theme.palette.primary.main }` : `1px solid ${theme.palette.greys.medium }`,
          cursor: 'pointer'
        },
        justifyContent: "start",
        display: "flex",
        alignItems: "center",
        paddingX:1,
        gap: 1,
        mt:1,
        mb:4
      })}
      onClick={onClick}
    >
      {icon}
      <Box flex={"1 1 100%"}>
        <Typography> {label} </Typography>
      </Box>
      {isOpen ? <ArrowDropUp sx={{justifySelf: "right", color:'greys.medium'}}/> : <ArrowDropDown sx={{justifySelf: "right", color:'greys.medium'}}/>}
    </Box>
  )
}

export default NestedDropdown