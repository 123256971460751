import React from 'react'
import { useBackgroundQuery } from '@apollo/client'
import _get from 'lodash/get'
import { GET_PATIENT_TASK_ASSIGNMENTS } from '../../../graphql-definitions'
import { TaskAssignmentType } from '../../../types/tasks/TaskAssignment'
import { useParams } from 'react-router-dom'

const getPatientTaskQueryResults = (data) => {
  return [..._get(data, 'tenant.patient.task.list', []) as Array<TaskAssignmentType>]
}

export const parsePatientTaskQueryResults = (data) => {
  return getPatientTaskQueryResults(data)
}

interface PatientTaskContextInterface {
  patientTasksRef: any,
  parsePatientTaskQueryResults: (any) => TaskAssignmentType[]
}

const defaultPatientTaskContext: PatientTaskContextInterface = {
  patientTasksRef: null,
  parsePatientTaskQueryResults: parsePatientTaskQueryResults,
}

const PatientTaskContext = React.createContext(defaultPatientTaskContext)

export const PatientTaskProvider = ({ children }) => {
  const { tenant_id, patient_id } = useParams()

  const [ patientTasksRef ] = useBackgroundQuery(GET_PATIENT_TASK_ASSIGNMENTS, {
    variables: {
      tenantId: tenant_id,
      patientId: patient_id
    }
  })

  const providerValues = {
    patientTasksRef,
    parsePatientTaskQueryResults
  }

  return (
    <PatientTaskContext.Provider value={providerValues}>
      { children }
    </PatientTaskContext.Provider>
  )
}

export const usePatientTaskContext = () => {
  return React.useContext(PatientTaskContext)
}