import { Section, useForm } from 'saga-library/src'
import React, { useEffect, useState } from 'react'
import { AbLabResultUpdate } from '../../../../types/patients'
import { LabAndInvestigationForm } from '../../../patients/components/labAndInvestigations/LabAndInvestigationForm'
import { FormProvider } from 'react-hook-form'
import { useMutation, useReadQuery } from '@apollo/client'
import _get from 'lodash/get'
import { LabsResultHeader } from './LabsHeader'
import { UPDATE_LAB_AND_INVESTIGATION, UPDATE_REVIEW_LAB } from '../../../../graphql-definitions'
import { useNavigate, useParams } from 'react-router-dom'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import {
  LabAndInvestigationFileNavigation
} from '../../../patients/components/labAndInvestigations/components/LabAndInvestigationFileNavigation'
import { FileType } from '../../../../types/tasks'
import { Box } from '@mui/material'
import { useReviewDocumentStateContext } from '../../providers/ReviewDocumentStateProvider'
import { ReviewLab, ReviewLabInput } from '../../../../types/inbox/ReviewLab'
import { useUpdateCount } from '../../../../hooks/useUpdateCount'
import { useAccountContext } from '../../../../providers/AccountContext'
import { usePrompt } from '../../../../providers/NavigationPrompt'
import { schema } from '../../../patients/components/labAndInvestigations/LabAndInvestigationFormValidationSchema'
import { useReviewLabsContext } from '../../providers/ReviewLabsProvider'

const FORM_NAME = 'labs_and_investigations_inbox_form'
const dataTestId = 'inbox'

interface LabsResultContentProps {
  labQuery: any,
}

export const LabsResultForm = ({
  labQuery,
}: LabsResultContentProps) => {
  const { tenant_id, user_id, role_id } = useParams()
  const { showErrorAlert, showSuccessAlert } = useAlerts()
  const { updateLabCount } = useUpdateCount()
  const { selectNextRow } = useReviewLabsContext()
  const documentStateContext = useReviewDocumentStateContext()
  const labData = useReadQuery(labQuery)
  const reviewLab = _get(labData, 'data.tenant.review.lab.get', null) as unknown as ReviewLab

  const [file, setFile] = useState<FileType | null>(null)
  const [files, setFiles] = useState<FileType[]>([])
  const [pendingNavigation, setPendingNavigation] = useState<ReviewLab | null>(null)

  const formMethods = useForm<AbLabResultUpdate>({
    schema: schema
  })

  const {
    watch,
    reset,
    formState: { dirtyFields, isSubmitting, isLoading: loading },
  } = formMethods

  const labNotes = watch('notes')

  const { enableNavigationPrompt } = usePrompt()
  const navigate = useNavigate()
  const { buildTenantRoute } = useAccountContext()
  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean>(false)

  const handleNavigationPromptDiscard = (discard: boolean) => {
    if (!discard) {
      setOpenNavigationPrompt(false)
      return
    }
    if (pendingNavigation && discard) {
      setOpenNavigationPrompt(false)
      navigate(buildTenantRoute(`inbox/${role_id ? `r/${role_id}` : `u/${user_id}`}/labs/l/${pendingNavigation.id}`, tenant_id))
      setPendingNavigation(null)
    }
  }

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, openNavigationPrompt, (discard) => handleNavigationPromptDiscard(discard))
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [Object.keys(dirtyFields).length, openNavigationPrompt])

  const [markAsReviewedMutation] = useMutation(UPDATE_REVIEW_LAB)
  const markAsReviewed = async () => {
    if (!reviewLab) return
    const reviewLabInput: ReviewLabInput = {
      id: reviewLab.id,
      userId: user_id,
      abLabResultVersion: reviewLab?.abLabResult?.version || '0',
      labNotes: labNotes,
      isReviewed: true,
      version: reviewLab?.version || '0'
    }

    reset({
      id: reviewLab?.id,
      notes: labNotes,
      version: reviewLab?.version
    })
    await markAsReviewedMutation({
      variables: {
        tenantId: tenant_id,
        input: reviewLabInput
      },
      onCompleted: (data) => {
        showSuccessAlert('Lab has been marked as reviewed.')
        const reviewLab = _get(data, 'tenant.review.lab.update', null) as unknown as ReviewLab
        selectNextRow(reviewLab.id, user_id)
        updateLabCount(-1)
      },
      onError: () => {
        showErrorAlert('Lab and investigation couldn\'t be saved')
      },
      update: (cache, data) => {
        const updatedLab = _get(data, 'data.tenant.review.lab.update') as unknown as ReviewLab
        if (!!updatedLab) {
          cache.modify({
            id: cache.identify(updatedLab),
            fields: {
              isReviewed: () => true
            }
          })
        }
      }
    })
  }

  const [updateLabAndInvestigationMutation] = useMutation(UPDATE_LAB_AND_INVESTIGATION)
  const updateLabAndInvestigation = async (labAndInvestigationInput, onSuccess) => {
    const id = labAndInvestigationInput.id
    delete labAndInvestigationInput.id

    await updateLabAndInvestigationMutation({
      variables: {
        tenantId: tenant_id,
        id: id,
        input: labAndInvestigationInput
      },
      onCompleted: (data) => {
        showSuccessAlert('Lab has been saved.')
        if (onSuccess) {
          const labAndInvestigation = _get(data, 'tenant.patient.labAndInvestigation.update', null)
          onSuccess(labAndInvestigation)
          reset({
            id: labAndInvestigation.id,
            notes: labAndInvestigation.notes,
            version: labAndInvestigation.version
          })
        }
      },
      onError: () => {
        showErrorAlert('Lab and investigation couldn\'t be saved')
      }
    })
  }

  useEffect(() => {
    if (!reviewLab?.abLabResult) return
    const resultsWithEmbeddedFile = reviewLab?.abLabResult?.abLabResultObservationResults
      ?.filter(r => r.file)
      ?.map(r => r.file!)

    setFiles(resultsWithEmbeddedFile)
    resultsWithEmbeddedFile?.length > 0 ? setFile(resultsWithEmbeddedFile[0]) : setFile(null)

    reset({
      id: reviewLab?.abLabResult?.id,
      notes: reviewLab?.abLabResult?.notes,
      version: reviewLab?.abLabResult?.version
    })
  }, [reviewLab])

  return (
    <Section.Column
      sx={{ pr: 1 }}
      width={'100%'}
      header={
        <LabsResultHeader
          lab={reviewLab?.abLabResult}
          loading={loading || isSubmitting}
          formName={FORM_NAME}
          isReviewed={reviewLab?.isReviewed}
          onMarkAsReviewedClicked={markAsReviewed}
          dataTestId={dataTestId}
        />
      }
    >
      {
        files?.length > 1 &&
        <Box
          pr={'32px'}
          display={'flex'}
          flexDirection={'row'}
          gap={1}
          alignItems={'center'}
        >
          <LabAndInvestigationFileNavigation
            files={files}
            selectedFile={file}
            setSelectedFile={setFile}
            dataTestId={'editLabAndInvestigation'}
          />
        </Box>
      }
      <FormProvider {...formMethods}>
        <LabAndInvestigationForm
          file={file}
          formName={FORM_NAME}
          labAndInvestigation={reviewLab?.abLabResult}
          updateLabAndInvestigationMethod={updateLabAndInvestigation}
          dataTestId={`${dataTestId}-labAndInvestigation`}
          documentStateContext={documentStateContext}
        />
      </FormProvider>
    </Section.Column>
  )
}