import React from "react";
import { useParams } from "react-router-dom";
import { useMutation, useBackgroundQuery, QueryReference } from "@apollo/client";
import { useAlerts } from "saga-library/src/providers/Alerts";
import {
  CREATE_LETTER, DELETE_LETTER,
  GET_PATIENT_LETTERS,
  UPDATE_LETTER
} from "../../../graphql-definitions/tenant/patient/LetterQueries";
import _get from "lodash/get";
import { LetterType } from "../../../types/patients";
import { LetterSortPredicate } from '../components/letters/LetterUtil'

const getLetterQueryResults = (data) => {
  return [..._get(data, 'tenant.patient.letter.list', []) as Array<LetterType>]
}

export const parseLetterQueryResults = (data) => {
  return getLetterQueryResults(data).sort(LetterSortPredicate)
}

const cleanLetterTemplateChips = (content: string) => {
  if (!content) {
    return ""
  }
  return content.replaceAll("fr-chip-label highlighted", "fr-chip-label")
}

interface LetterContextInterface {
  letterQueryRef: QueryReference | null
  createLetter: (
    letter: LetterType,
    onSuccess?: (letter:LetterType) => void
  ) => Promise<void>,
  updateLetter: (
    letter: LetterType,
    onSuccess?: (letter:LetterType) => void
  ) => Promise<void>,
  deleteLetter: (
    letter: LetterType,
    onSuccess?: () => void
  ) => Promise<void>,
  getLetterQueryResults: (any) => LetterType[]
  parseLetterQueryResults: (any) => LetterType[]
}

const defaultLetterContext: LetterContextInterface = {
  letterQueryRef: null,
  createLetter: () => new Promise<void>(() => {}),
  updateLetter: () => new Promise<void>(() => {}),
  deleteLetter: () => new Promise<void>(() => {}),
  getLetterQueryResults: getLetterQueryResults,
  parseLetterQueryResults: parseLetterQueryResults
}


const LetterContext = React.createContext(defaultLetterContext)

export const LetterProvider = ({ children }) => {
  const { tenant_id, patient_id } = useParams()
  const { showErrorAlert, showSuccessAlert } = useAlerts()

  const [ createLetterMutation ] = useMutation(CREATE_LETTER)
  const [ updateLetterMutation ] = useMutation(UPDATE_LETTER)
  const [ deleteLetterMutation ] = useMutation(DELETE_LETTER)

  const [letterQueryRef] = useBackgroundQuery(GET_PATIENT_LETTERS, {
    variables: {
      patientId: patient_id,
      tenantId: tenant_id
    }
  })


  const createLetter = async(letter, onSuccess) => {
    delete letter.recipients
    delete letter.templateReferralPractitioner
    delete letter.templateFamilyPractitioner

    letter.content = cleanLetterTemplateChips(letter.content)

    await createLetterMutation({
      variables: {
        tenantId: tenant_id,
        input: letter
      },
      onCompleted: (data) => {
        const letterResult = _get(data, 'tenant.patient.letter.create', null)
        showSuccessAlert("Letter has been saved")
        if(onSuccess) {
          onSuccess(letterResult)
        }
      },
      onError: (error) => {
        showErrorAlert("Letter couldn't be created")
      },
      update: (cache, { data }) => {
        const newLetter = _get(data, 'tenant.patient.letter.create', null)
        updatePatientLetterCache(cache, newLetter, tenant_id, patient_id)
      }
    })
  }


  const updateLetter = async (letter, onSuccess) => {
    const letterId = letter.id
    delete letter.letterTemplate
    delete letter.recipients
    delete letter.id
    delete letter.practitioner
    delete letter.templateReferralPractitioner
    delete letter.templateFamilyPractitioner

    letter.content = cleanLetterTemplateChips(letter.content)

    await updateLetterMutation({
      variables:{
        tenantId: tenant_id,
        letterId: letterId,
        input: letter
      },
      onCompleted: (data) => {
        const letterResult = _get(data, 'tenant.patient.letter.create', null)

        showSuccessAlert("Letter has been saved")
        if(onSuccess) {
          onSuccess(letterResult)
        }
      },
      onError: (error) => {
        showErrorAlert("Letter couldn't be saved")
      }
    })
  }


  const deleteLetter = async(letter, onSuccess) => {
    await deleteLetterMutation({
      variables:{
        tenantId: tenant_id,
        letterId: letter.id,
        version: letter.version
      },
      onCompleted: (data) => {
        showSuccessAlert("Letter has been deleted")
        if(onSuccess) {
          onSuccess()
        }
      },
      onError: (error) => {
        showErrorAlert("Letter couldn't be deleted")
      },
      update: (cache, { data }) => {
        const normalizedId = cache.identify({
          id: letter.id,
          __typename: 'Letter',
          isLinkedDocument: false
        })
        cache.evict({id:normalizedId})
        cache.gc()
      }
    })
  }

  const providerValues = {
    letterQueryRef,
    createLetter,
    updateLetter,
    deleteLetter,
    getLetterQueryResults,
    parseLetterQueryResults
  }

  return (
    <LetterContext.Provider value={providerValues}>
      { children }
    </LetterContext.Provider>
  )
}

export const useLetterContext = () => {
  return React.useContext(LetterContext)
}

const updatePatientLetterCache = async (cache, letter, tenantId, patientId) => {
  await cache.updateQuery({
    query: GET_PATIENT_LETTERS,
    variables: {
      tenantId: tenantId,
      patientId: patientId
    }
  },(data) => {
    const existingLetters = _get(data, 'tenant.patient.letter.list', []).filter(d => d.id !== letter.id || d.isLinkedDocument !== letter.isLinkedDocument)
    return {
      tenant:{
        patient:{
          letter:{
            list: [...existingLetters, letter]
          }
        }
      }
    }
  })
}

const transformLinkedDocument = (document) => {
  const title = !!document.description || document.description.trim().length > 0 ? document.description : "Letter"

  return {
    id: document.id,
    isLinkedDocument: true,
    title: title,
    letterDate: document.documentDate,
    linkedDocument: document,
    __typename: 'Letter',
    letterTemplateId: null,
    letterTemplate: null,
    content: null,
    notes: null,
    practitionerId: null,
    practitioner: null,
    recipients: null,
    version: null,
    audit: document.audit
  }
}

export const moveDocumentToLetter = async(cache, document, tenantId, patientId) => {
  const newLetter = transformLinkedDocument(document)
  await updatePatientLetterCache(cache, newLetter, tenantId, patientId)
}