import { Section } from 'saga-library/src'
import React, { Suspense, useEffect } from 'react'
import { useLoadableQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { LabsResultForm } from './LabsResultForm'
import { LoadingSpinner } from '../../../../components/LoadingScreen'
import { GET_REVIEW_LAB_AND_INVESTIGATION } from '../../../../graphql-definitions'

export const LabsResult = () => {
  const { tenant_id, lab_id } = useParams()

  const [loadGetLab, loadGetLabQueryRef] = useLoadableQuery(GET_REVIEW_LAB_AND_INVESTIGATION,
    {fetchPolicy: 'cache-and-network'}
  )

  useEffect(() => {
    if(!tenant_id || !lab_id || !loadGetLab) return

    loadGetLab({
      id: lab_id,
      tenantId: tenant_id,
    })
  }, [lab_id, tenant_id ])

  return (
    <Suspense fallback={<LoadingLabSelection />}>
      {
        !!loadGetLabQueryRef && (
          <LabsResultForm
            labQuery={loadGetLabQueryRef}
          />
        )
      }
      </Suspense>
  )
}

const LoadingLabSelection = () => {
  return (
    <Section.Column rightPadding={1} sx={{ width: "100%" }}>
      <LoadingSpinner />
    </Section.Column>
  )
}