import { gql } from "@apollo/client";
import { SettingsFragment } from "../SettingsQueries";

export const GET_TENANT_USERS = gql`
  query GetTenantUsers($tenantId: ID!, $onlyActive: Boolean) {
    tenant(id: $tenantId) {
      user {
        allTenantUsers(onlyActive: $onlyActive) {
          id
          firstName
          lastName
          lastSignIn
          active
          emailAddress
          roleIds
          isPractitioner
        }
      }
      role {
        tenantRoles {
          id
          name
          description
          isOwner
          isAdmin
        }
      }
      invite {
        list {
          id
          expires
          roleIds
          redeemed
          emailAddress
        }
      }
    }
  }
`

export const GET_TENANT_USERS_BY_ID = gql`
  query GetTenantUsersById($tenantId: ID!, $ids: [ID!]!) {
    tenant(id: $tenantId) {
      user {
        tenantUsersById(ids: $ids) {
          firstName
          lastName
        }
      }
    }
  }
`

export const GET_TENANT_USER_BY_ID = gql`
  query($tenantId: ID!, $userId: ID!) {
    tenant(id: $tenantId) {
      user {
         user(id: $userId) {
            id
            firstName
            lastName
            lastSignIn
            active
            emailAddress
            roleIds
            isPractitioner
            practitionerIds
         }
      }
    }
  }
`

export const REVOKE_ACCESS_MUTATION = gql`
  mutation RevokeUserAccess($tenantId: ID!, $userId: ID!, $version: String!) {
    tenant(id: $tenantId) {
      user {
        admin {
          deactivateUser(id: $userId, version: $version)
        } 
      }
    }
  }
`

export const RESTORE_ACCESS_MUTATION = gql`
  mutation RestoreUserAccess($tenantId: ID!, $userId: ID!, $version: String!) {
    tenant(id: $tenantId) {
      user {
        admin {
          reactivateUser(id: $userId, version: $version)
        } 
      }
    }
  }
`

export const UPDATE_TENANT_USER_SETTING = gql`
  ${SettingsFragment}
  mutation UpdateTenantUserSetting($tenantId: ID!, $setting:SettingInput!){
    tenant(id: $tenantId) {
      user {
        updateTenantUserSetting(setting: $setting) {
          ...Settings
        }
      }
    }
  }
`

export const GET_USER_TENANT_SETTINGS = gql`
  ${SettingsFragment}
  query GetTenantUserSetting($tenantId: ID!, $keys: [String!]!){
    tenant(id: $tenantId){
      user{
        settings(keys: $keys){
          ...Settings
        }
      }
    }
  }
`
