import { useParams } from 'react-router-dom'
import { useSuspenseQuery } from '@apollo/client'
import { GET_PATIENT_TASK_ASSIGNMENTS } from '../../../../../graphql-definitions'
import { parsePatientTaskQueryResults } from '../../../../patients/providers/PatientTaskProvider'
import { EmptyList, IconImageTooltip, Typography } from 'saga-library/src'
import { TaskAssignmentType } from '../../../../../types/tasks/TaskAssignment'
import { TaskDate } from '../../../../patients/components/widgets/TaskWidget'
import { TaskPriorityMap } from '../../../../../types/tasks/TaskPriority'
import React from 'react'
import { ItemType, ListBox, ListMenuItem } from '../LinkItem'

export const TaskList = ({patientId, onSelect}) => {
  const { tenant_id } = useParams()

  const { data } = useSuspenseQuery(GET_PATIENT_TASK_ASSIGNMENTS , {
    variables: {
      patientId: patientId,
      tenantId: tenant_id
    }
  })

  const tasks = parsePatientTaskQueryResults(data)

  if (tasks.length > 0) {
    return (
      <ListBox>
        {tasks.map(t => <TaskItem item={t} onClick={onSelect} key={`link_patient_task_${t.id}`} />)}
      </ListBox>
    )
  }

  return (
    <EmptyList sx={{ p: 1, width: "200px" }} message={`No associated other documents`} />
  )
}

export const TaskItem = ({item, onClick, icon, ...itemTypeProps}:ItemType) => {
  const task = item as TaskAssignmentType

  return (
    <ListMenuItem
      onClick={onClick ? (e) => onClick(e, task) : undefined}
      icon={icon}
      {...itemTypeProps}
    >
      <TaskDate {...task} />
      <IconImageTooltip title={TaskPriorityMap.get(task.priority!)!.label}>
        {TaskPriorityMap.get(task.priority!)!.icon}
      </IconImageTooltip>
      <Typography lineclamp={1}>{task.type.type}</Typography>
      <Typography lineclamp={1}>{task.name}</Typography>
    </ListMenuItem>
  )
}