import React, { useState } from 'react'
import MuiAutocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import { SimpleTextField } from "../TextField";
import { FieldError } from "saga-library/src/components/Form";
import { SxProps } from "@mui/system";
import { Box, InputProps, Theme, useTheme } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClearIcon from "@mui/icons-material/Clear";

export interface StyledAutocompleteProps {
  name?: string
  label: string
  placeholder?: string
  options: object[]
  isOptionEqualToValue?: (option, value) => boolean
  OptionComponent?: React.ComponentType<OptionComponentProps>
  onChange: (e, newValue, reason?) => void
  inputValue?: string
  onInputChange?: (e, value, reason?) => void
  value: object | object[] | null | undefined
  multiple?: boolean
  disableChips?: boolean
  optionLabel?: string
  getOptionLabel?: (option) => string
  filterOptions?: (options, state) => any[]
  error?: FieldError
  helperText?: string
  autoFocus?: boolean
  onFocus?: () => void
  inputRef?: React.Ref<HTMLInputElement>
  loading?: boolean
  disabled?: boolean
  disableClearable?: boolean
  endAdornment?: React.ReactElement
  InputProps?: Partial<InputProps>
  sx?: SxProps<Theme>
  inputSx?: SxProps<Theme>
  optionSx?: SxProps<Theme>
  getOptionDisabled?: (option) => boolean
  freeSolo?: boolean
  autoSelect?: boolean
  tabAutoSelect?: boolean
  autoHighlight?: boolean
  dataTestId?: string
}

interface OptionComponentProps {
  data: object
  props: object
  inputValue: string
  dataTestId?: string
}

const defaultStyles = {
  fontSize: '12px',
}

export const Autocomplete = ({
  label,
  placeholder,
  options = [],
  name,
  OptionComponent,
  onChange,
  inputValue,
  onInputChange,
  freeSolo,
  autoSelect = false,
  tabAutoSelect = true,
  autoHighlight = autoSelect || tabAutoSelect,
  multiple = false,
  disableChips = false,
  isOptionEqualToValue,
  getOptionLabel = (option) => option.label,
  value,
  filterOptions,
  error,
  helperText,
  autoFocus = false,
  onFocus,
  inputRef,
  loading = false,
  disabled = false,
  disableClearable = false,
  endAdornment,
  InputProps,
  getOptionDisabled = () => false,
  sx = {},
  inputSx = {},
  optionSx = {},
  dataTestId
}: StyledAutocompleteProps) => {

  const optionalProps: {
    renderTags?: (value: Array<object>, getTagProps: Function, ownerState: object) => React.ReactNode
    isOptionEqualToValue?: (option, value) => boolean
  } = {}

  if (multiple && disableChips) {
    optionalProps.renderTags = () => null
  }

  if (isOptionEqualToValue) {
    optionalProps.isOptionEqualToValue = isOptionEqualToValue
  }

  const theme = useTheme()

  const componentsProps = {
    paper:{
      sx: {
        "& .MuiAutocomplete-option[aria-selected='true']": {
          backgroundColor: `${theme.palette.backgrounds.selected} !important`,
        },
        "& .MuiAutocomplete-option[aria-selected='true']:hover": {
          backgroundColor: `${theme.palette.backgrounds.selectedHover} !important`,
        },
        "& .MuiAutocomplete-option[aria-selected='false']:hover": {
          backgroundColor: `${theme.palette.backgrounds.hover} !important`
        },
        "& li.MuiAutocomplete-option": {
          ...optionSx
        }
      }
    },
    clearIndicator: {
      sx: {
        '&:hover': {
          backgroundColor: theme.palette.backgrounds.hover,
        },
      },
      'data-testid': `${dataTestId}-clear-button`
    },
    popupIndicator: {
      sx: {
        '&:hover': {
          backgroundColor: theme.palette.backgrounds.hover,
        },
      },
      'data-testid': `${dataTestId}-dropDown-button`
    }
  }

  if (OptionComponent) {
    return (
      <AutocompleteBase
        data-testid={dataTestId}
        size={'small'}
        loading={loading}
        renderInput={(params) => (
          <SimpleTextField
            {...params}
            name={name}
            value={value}
            inputRef={inputRef}
            dataTestId={`${dataTestId}-textfield`}
            label={label}
            placeholder={placeholder}
            error={!!error}
            helperText={helperText}
            autoFocus={autoFocus}
            onFocus={onFocus}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: '8px'
              },
              ...inputSx
            }}
            disabled={disabled}
            extraEndAdornment={endAdornment}
            InputProps={{
              ...params.InputProps,
              ...InputProps
            }}

          />
        )}
        inputValue={inputValue}
        onInputChange={onInputChange}
        options={options}
        getOptionLabel={getOptionLabel}
        renderOption={(props, option, {inputValue, index}) => (
          <OptionComponent
            dataTestId={`${dataTestId}-option-${index}`}
            key={option.name || option.label}
            data={option}
            props={props}
            inputValue={inputValue}
          />
        )}
        onChange={onChange}
        value={value}
        multiple={multiple}
        filterOptions={filterOptions}
        disabled={disabled}
        disableClearable={disableClearable}
        getOptionDisabled={getOptionDisabled}
        freeSolo={freeSolo}
        autoSelect={autoSelect}
        tabAutoSelect={tabAutoSelect}
        autoHighlight={autoHighlight}
        sx={{
          ...defaultStyles,
          ...sx
        }}
        componentsProps={componentsProps}
        clearIcon={<ClearIcon fontSize="small" data-testid={`${dataTestId}-clear-icon`} />}
        popupIcon={<ArrowDropDownIcon data-testid={`${dataTestId}-dropDown-icon`} />}
        {...optionalProps}
      />
    )
  }

  return (
    <AutocompleteBase
      data-testid={dataTestId}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <SimpleTextField
          {...params}
          name={name}
          value={value}
          inputRef={inputRef}
          dataTestId={`${dataTestId}-textfield`}
          label={label}
          placeholder={placeholder}
          error={!!error}
          helperText={helperText}
          autoFocus={autoFocus}
          onFocus={onFocus}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: '8px'
            },
          }}
          disabled={disabled}
          extraEndAdornment={endAdornment}
        />
      )}
      sx={{...sx}}
      disabled={disabled}
      disableClearable={disableClearable}
      style={defaultStyles}
      getOptionLabel={getOptionLabel}
      renderOption={(props, option: any, { index }) => (
        <Box
          component={"li"}
          {...props}
          key={option.id ?? option.name ?? option.label ?? option}
          data-testid={`${dataTestId}-option-${index}`}
        >
          {!!getOptionLabel ? getOptionLabel(option) : (option.label || option)}
        </Box>
      )}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionDisabled={getOptionDisabled}
      onChange={onChange}
      onInputChange={onInputChange}
      freeSolo={freeSolo}
      autoSelect={autoSelect}
      tabAutoSelect={tabAutoSelect}
      autoHighlight={autoHighlight}
      value={value}
      componentsProps={componentsProps}
      clearIcon={<ClearIcon fontSize="small" data-testid={`${dataTestId}-clearIcon`} />}
      popupIcon={<ArrowDropDownIcon data-testid={`${dataTestId}-dropDownIcon`} />}
      {...optionalProps}
    />
  )
}

export interface BaseAutocompleteProps<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined
> extends AutocompleteProps<
    T,
    Multiple,
    DisableClearable,
    FreeSolo
> {
  tabAutoSelect?: boolean
}

const AutocompleteBaseComponent = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  autoSelect = false,
  tabAutoSelect = true,
  autoHighlight = autoSelect || tabAutoSelect,
  ...props
}: BaseAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, ref?: React.Ref<HTMLDivElement>) => {
  const [tabPressed, setTabPressed] = useState<boolean>(false)

  const onKeyDown = (e) => e.key === "Tab" ? setTabPressed(true) : null

  return (
    <MuiAutocomplete
      ref={ref}
      onKeyDown={tabAutoSelect ? onKeyDown : undefined}
      onBlur={() => setTabPressed(false)}
      autoSelect={autoSelect || (tabAutoSelect && tabPressed)}
      autoHighlight={autoHighlight}
      {...props}
    />
  )
}

export const AutocompleteBase = React.forwardRef(AutocompleteBaseComponent) as <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(props: BaseAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) => JSX.Element

export default Autocomplete
