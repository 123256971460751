import React from 'react'
import { TableListRowConfig, Typography } from "saga-library/src";
import { AbClaim } from '../../../types/billing'
import { NameAndIdentifier } from "saga-library/src/components/TableList/CellBlocks/NameAndIdentifier";
import { practitionerDisplayName, referralPractitionerDisplayName } from "saga-library/src/util/formatting";
import moment from 'moment-timezone'

export const PatientClaimRow = (claim: AbClaim, dateFormat: string): TableListRowConfig => {
  return ({
    key: claim.id,
    rowData: [
      {
        children: (
          <Typography variant={'body2'}>{moment(claim.serviceDate).format(dateFormat)}</Typography>
        )
      },
      {
        children: (
          <Typography variant={'body2'}>{claim.serviceCode.serviceCode}</Typography>
        )
      },
      {
        children: (
          <Typography variant={'body2'}>{claim.calls}</Typography>
        )
      },
      {
        children: (
          <Typography variant={'body2'}>{claim.diagnosticCodes.map((dc) => {
            return dc?.diagnosticCode
          })}</Typography>
        )
      },
      {
        children:(
          <NameAndIdentifier
            name={practitionerDisplayName(claim.billingProfile?.practitionerFirstName, claim.billingProfile?.practitionerLastName)}
            identifier={claim.billingProfile?.name}
          />
        )
      },
      {
        children: (
          <Typography variant={'body2'} sx={{textAlign: 'right'}}>{claim.claimAmount != null? `$${claim.claimAmount.toFixed(2)}` : ''}</Typography>
        )
      },
      {
        children: (
          <Typography variant={'body2'}>{claim.actionCode}</Typography>
        )
      },
      {
        children: (
          <Typography variant={'body2'}>{claim.claimStatus}</Typography>
        )
      },
      {
        children: (
          <Typography variant={'body2'} sx={{textAlign: 'right'}}>{claim.assessmentAmount != null? `$${claim.assessmentAmount.toFixed(2)}` : ''}</Typography>
        )
      },
      {
        children: (
          claim.referralPractitioner &&
          <NameAndIdentifier
            name={referralPractitionerDisplayName(claim.referralPractitioner?.firstName, claim.referralPractitioner?.lastName)}
            identifier={claim.referralPractitioner?.practitionerId}
          />
        ),
      },
    ]
  })
}
