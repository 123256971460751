import React from 'react'
import { ConfirmationDialog } from 'saga-library/src'
import { useReviewContext } from '../../providers/ReviewProvider'

export const MarkDocumentReviewedDialog = ({ open, onClose, onSuccess, selectedDocuments }) => {
  const { markDocumentsAsReviewed } = useReviewContext()

  const onCancel = () => {
    onClose()
  }

  const onSave = async () => {
    await markDocumentsAsReviewed(selectedDocuments, () => {
      onSuccess()
      onClose()
    })
  }

  return (
    <ConfirmationDialog
      open={open}
      onClose={onCancel}
      primaryAction={onSave}
      primaryLabel={'MARK AS REVIEWED'}
      message={"This action can't be undone."}
      title={'Mark selected document(s) as reviewed?'}
      dataTestId={'mark-document-reviewed-confirmation'}
    />
  )
}