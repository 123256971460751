import React, { Children, ReactNode, useEffect, useState } from "react";
import {
  AddButton,
  AddressLines,
  Checkbox,
  ColorPicker,
  PhoneComponent,
  PostalCodeField,
  ProvinceSelect,
  Select,
  Switch,
  TextField,
  TextFieldMask,
  Form
} from "saga-library/src";
import { Address, CitySearch } from './Address'
import PermissionSwitch from "../apps/settings/components/TenantRole/PermissionSwitch";
import PermissionSwitchGroup from "../apps/settings/components/TenantRole/PermissionSwitchGroup";
import { CopyRoleToggle } from '../apps/settings/components/TenantRole/TenantRoleForm'
import { useParams } from "react-router-dom";
import { useAccountContext } from "../providers/AccountContext";
import { Permission } from "../types/settings/Permission";
import { HasPermissionTemplate } from "./HasPermissionTemplate";
import { ControlledUserSearch } from "../apps/settings/components/TenantRole/TenantRoleUsers";
import { FacilitySearch } from "./SearchControls/Claims/FacilitySearch";
import { ControlledFunctionalCenterInput } from "./FunctionalCenterSearch/FunctionalCenterInput";
import { SkillSelect } from "./SkillSelect";
import { LocationCodeSelect } from "./LocationCodeSelect";
import { PhoneComponentList } from "saga-library/src/components/Phone/Phone";
import { DisciplineSelect } from "./DisciplineSelect";
import { InterceptReasonSelect } from "./InterceptReasonSelect";
import FormattedDatePicker from "./FormattedDatePicker";
import NewbornCodeSelect from "./NewbornCodeSelect/NewbornCodeSelect";
import { ControlledBillingProfileSelect } from "./BillingProfileSelect/BillingProfileSelect";
import { PatientLookup } from "./PatientLookup/PatientLookup";
import { ReferralPractitionerSearch } from "./SearchControls/Practitioner/ReferralPractitionerSearch";
import { ServiceCodeSearch } from "./SearchControls/Claims/ServiceCodeSearch"
import { DiagnosticCodeSearch } from "./SearchControls/Claims/DiagnosticCodeSearch";
import { ServiceFeeModifierSelect } from "./ServiceFeeModifierSelect";
import { ClaimAmount } from "./ClaimAmount";
import { ControlledScheduleAutocompleteSelect } from "./ScheduleAutocompleteSelect";
import { PractitionersForLocationSelect } from './PractitionersForLocationSelect'
import { PractitionerSelect } from "./PractitionersSelect";
import { DosageManager } from "../apps/patients/components/prescriptions/components/DosageManager";
import { DictionarySelect } from "../apps/patients/components/prescriptions/components/DictionarySelect";
import ToggleButtonGroup from "saga-library/src/components/ToggleButtonGroup/ToggleButtonGroup";
import { ReferralPractitionerChipSelect } from "./ReferralPractitionerChipSelect";
import { TemplateSelect } from "./TemplateSelect";
import { SignaturePicker } from '../apps/settings/Practitioners/components/SignatureDetails'
import { LinkedUserAccount } from '../apps/settings/Practitioners/components/AdministrationDetails'
import { PractitionerMaskedTextField } from './PractitionerMaskedTextField'
import { PractitionerSchedules } from '../apps/settings/Practitioners/components/Schedules/PractitionerSchedulesDetails'
import { EncounterNoteTemplateFields } from '../apps/patients/components/encounterNotes/EncounterNoteTemplateFields'
import { AssignedTo } from './AssignedTo'
import { ControlledPractitionerAutocompleteMultiSelect } from './PractitionerAutocompleteMultiSelect'
import { RichTextEditorClient } from './RichTextEditorClient'

const disablableComponents = [
  Select,
  TextField,
  PermissionSwitch,
  Switch,
  CopyRoleToggle,
  PermissionSwitchGroup,
  ControlledUserSearch,
  ProvinceSelect,
  PostalCodeField,
  TextFieldMask,
  AddButton,
  PhoneComponent,
  CitySearch,
  FacilitySearch,
  ControlledFunctionalCenterInput,
  SkillSelect,
  LocationCodeSelect,
  PhoneComponentList,
  AddressLines,
  DisciplineSelect,
  InterceptReasonSelect,
  Checkbox,
  FormattedDatePicker,
  NewbornCodeSelect,
  ControlledBillingProfileSelect,
  PatientLookup,
  ReferralPractitionerSearch,
  ServiceCodeSearch,
  DiagnosticCodeSearch,
  ServiceFeeModifierSelect,
  ClaimAmount,
  ColorPicker,
  ControlledScheduleAutocompleteSelect,
  ControlledPractitionerAutocompleteMultiSelect,
  FacilitySearch,
  PractitionersForLocationSelect,
  PractitionerSelect,
  DosageManager,
  DictionarySelect,
  ToggleButtonGroup,
  RichTextEditorClient,
  ReferralPractitionerChipSelect,
  TemplateSelect,
  Address,
  SignaturePicker,
  LinkedUserAccount,
  PractitionerMaskedTextField,
  PractitionerSchedules,
  Select,
  EncounterNoteTemplateFields,
  AssignedTo
]

export const PermissionForm = ({
  children ,
  id="",
  name='permission_form',
  onSubmit,
  requiredPermissionType,
  readOnlyOverride = false,
  sx = {},
  formDisabled = false,
}) => {

  return <HasPermissionTemplate requiredType={requiredPermissionType} requiredPermission={Permission.READONLY} overridePermission={readOnlyOverride}>
    <Form
      autoComplete={'off'}
      name={name}
      onSubmit={onSubmit}
      id={id}
      style={sx}
    >
      <FormFragment requiredPermissionType={requiredPermissionType} readOnlyOverride={readOnlyOverride} formDisabled={formDisabled}>
        { children }
      </FormFragment>
    </Form>
  </HasPermissionTemplate>
}

export const FormFragment = ({
  children: parentChildren,
  requiredPermissionType,
  readOnlyOverride = false,
  formDisabled = false
}) => {
  const { tenant_id } = useParams()
  const { userHasPermission } = useAccountContext()
  const [children, setChildren] = useState(parentChildren)

  const readWrite = tenant_id && userHasPermission(tenant_id, requiredPermissionType, Permission.READWRITE)
  const readOnly = tenant_id && userHasPermission(tenant_id, requiredPermissionType, Permission.READONLY) || readOnlyOverride

  useEffect( () => {
    if(readWrite && !formDisabled){
      setChildren(parentChildren)
    } else {
      setChildren(recursiveMap(parentChildren, (child) => {
        if (React.isValidElement(child)) {
          //@ts-ignore
          if ( disablableComponents.includes(child?.type) ) {
            //@ts-ignore
            return React.cloneElement(child, { disabled: readOnly || formDisabled })
          }
          return child
        }
      }))
    }
  }, [parentChildren])

  return <>
    {children}
    </>
}


const recursiveMap = (children, callback: (child: ReactNode) => ReactNode) =>
  Children.map(children, (child: ReactNode) => {
    if (!React.isValidElement(child)) {
      return child;
    }
    if (child.props.children) {
      child = React.cloneElement(child, {
        ...child.props,
        children: recursiveMap(child.props.children, callback),
      });
    }

    return callback(child);
  });